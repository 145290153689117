<template>
  <div class="">
    <div class="lg:mt-24 mt-12">
      <img
        class="w-full"
        src="../assets/restaurantensavoirplus.jpg"
        alt="Image 2"
      />
    </div>
    <div v-show="showAlert">
      <AlertComponent :content="alert.message" type-alert="error" />
    </div>

    <div class="bg-white rounded-lg shadow-lg p-6 lg:mt-4">
      <div
        v-for="(restaurant, index) in filteredRestaurants"
        :key="index"
        class="lg:flex lg:ml-80 shadow-xl lg:mr-80 lg:h-80 "
      >
        <img
          class="transform transition duration-700 hover:scale-125 lg:h-72 lg:ml-24 mt-2 lg:w-1/2"
          :src="restaurant.image_url"
          alt="{title}"
        />
        <div class="lg:ml-12 mt-6 text-left ml-9 ">
          <h2 class="text-2xl font-semibold mb-2">{{ restaurant.name }} <span class="ml-4" :class="statusClass(restaurant.status)">
          {{ restaurant.status }}
        </span></h2>
          <p class="text-gray-600 text-sm mb-2 mt-2 mr-2 hidden md:block">
            {{ restaurant.description }}
          </p>
          <p class="text-gray-600 text-sm mb-2">
            A {{ restaurant.ville }} {{ restaurant.quatier }}
            {{ restaurant.adresse }}
          </p>
          <p class="text-gray-600 text-lg mb-2 font-bold text-green-500">
            Spécialité {{ restaurant.specialite.name }}
          </p>
          <p class="text-gray-600 text-sm mb-2">
            avec une capacité de {{ restaurant.capacite }} place
          </p>
          <p class="text-gray-600 text-sm mb-2">
            Ouvre à {{ restaurant.heure_douverture }} et ferme à
            {{ restaurant.heure_fermeture }}
          </p>
          <div class="flex">
            <button
              class="bg-green-600 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-full"
              @click="showModalReservations = true"
            >
              Réserver
            </button>
            <button class="ml-6" @click="CommentaireRestoModal(restaurant.id)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-9 h-9"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
                />
              </svg>
            </button>
            <button class="ml-6" @click="NoteRestoModal(restaurant.id)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-9 h-9 text-green-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                />
              </svg>
            </button>
            
          </div>
          <div class="h-5"></div>
        </div>
      </div>
      <h3 class="text-2xl font-semibold mt-9">Nos menus</h3>
      <div class="flex mt-4 flex-wrap lg:p-12">
        <div v-for="(repas, index) in menus" :key="index" class="lg:w-1/4 p-4">
          <div class="bg-white relative">
            <img
              class="h-60 w-72 mx-auto transform transition duration-300 hover:scale-105"
              :src="repas.image_url"
              alt=""
            />
            <button
              class="absolute bottom-48 lg:left-5 left-2  bg-green-500 text-white py-2 px-4 rounded-bl-lg"
            >
              {{ repas.categoris.name }}
            </button>
          </div>
          <div class="flex flex-col items-center my-3 space-y-2">
            <h1 class="text-gray-900 poppins text-lg">{{ repas.name }}</h1>
            <p class="text-gray-500 poppins text-sm text-center">
              {{ repas.description }} 
            </p>
            <h2 class="text-gray-900 poppins text-2xl font-bold">
              {{ repas.prix.split(".")[0] }}FCFA
            </h2>
            <div class="flex">
              <button
                @click="RepasModal(repas.id, repas.restaurant.status)"
                class="bg-green-600 text-white mr-6 px-8 py-2 focus:outline-none poppins rounded-full mt-4 transform transition duration-300 hover:scale-105"
              >
                Commander
              </button>

              <button class="ml-4 mt-4" @click="CommentaireModal(repas.id)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-9 h-9"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <TheModal
    width="w-full md:w-2/3 lg:w-1/2"
    :is-open="showModalReservations"
    @close-modal="showModalReservations = false"
  >
    <template #header> Reservation</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="reservation()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div class="col-span-8 sm:col-span-4">
                  <BaseLabel value="Nom " />
                  <BaseInput id="nom" v-model="addform.name" class="mt-2" />
                </div>
                <div class="col-span-8 sm:col-span-4">
                  <BaseLabel value="Nombre de place" />
                  <div class="flex">
                    <BaseInput
                      id="prenom"
                      v-model="addform.place"
                      class="mt-2"
                    />
                  </div>
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Informations Complémentaires" />
                  <BaseInput
                    id="language"
                    v-model="addform.description"
                    class="mt-2"
                  />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Contact" />
                  <BaseInput
                    id="language"
                    v-model="addform.contact"
                    class="mt-2"
                  />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Date et Heure" />
                  <input
                    type="datetime-local"
                    name="date"
                    id="date"
                    v-model="addform.date"
                    class="block w-full p-2 border border-input-disable rounded-md focus:outline-none focus:ring-primary-normal mt-2 focus:ring focus:ring-opacity-50 shadow-sm focus:border"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <AddModalFooter
        @cancel="showModalReservations = false"
        @send="reservation()"
      />
    </template>
  </TheModal>
  <TheModal
    width="w-full md:w-2/3 lg:w-1/2"
    :is-open="showModalRepas"
    @close-modal="showModalRepas = false"
  >
    <template #header> Faire votre commande</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="commande()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div class="col-span-8 sm:col-span-4">
                  <BaseLabel value="Nom " />
                  <BaseInput id="nom" v-model="addforms.name" class="mt-2" />
                </div>
                <div class="col-span-8 sm:col-span-4">
                  <BaseLabel value="Addrese" />
                  <BaseInput
                    id="prenom"
                    v-model="addforms.adresse"
                    class="mt-2"
                  />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Télèphone" />
                  <div class="relative mt-1">
                    <BaseInput v-model="addforms.contact" class="mt-2" />
                  </div>
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel
                    value="Informations Complémentaires (pas obligatoire)"
                  />
                  <BaseInput
                    id="language"
                    v-model="addforms.description"
                    class="mt-2"
                  />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Qunatité" />
                  <BaseInput
                    id="language"
                    v-model="addforms.quantite"
                    class="mt-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <AddModalFooter @cancel="showModalRepas = false" @send="commande()" />
    </template>
  </TheModal>
  <TheModal
    width="w-full md:w-2/3 lg:w-1/2"
    :is-open="showModalCommentaires"
    @close-modal="showModalCommentaires = false"
  >
    <template #header> Votre commentaire</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="commentaire()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Votre message " />
                  <div class="mt-1">
                    <textarea
                      class="block w-full p-2 border border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
                      v-model="sendform.content"
                      autocomplete="current-password"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <AddModalFooter
        @cancel="showModalCommentaires = false"
        @send="commentaire()"
      />
    </template>
  </TheModal>
  <TheModal
    width="w-full md:w-2/3 lg:w-1/2"
    :is-open="showModalRestoCommentaires"
    @close-modal="showModalRestoCommentaires = false"
  >
    <template #header> Votre commentaire</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="commentaireResto()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Votre message " />
                  <div class="mt-1">
                    <textarea
                      class="block w-full p-2 border border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
                      v-model="sendforms.content"
                      autocomplete="current-password"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <AddModalFooter
        @cancel="showModalRestoCommentaires = false"
        @send="commentaireResto()"
      />
    </template>
  </TheModal>
  <TheModal
    width="w-full md:w-2/3 lg:w-1/4"
    :is-open="showModalCommande"
    @close-modal="showModalCommande = false"
  >
    <template #header> Numero de payement</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="LivreCommande()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div
                  v-if="numResto.celtispay !== null"
                  class="col-span-8 sm:col-span-8 flex items-center"
                >
                  <BaseLabel value="Numéro CELTISE" />
                  <label
                    class="block font-medium text-sm text-gray-700 mt-2 bg-gray-200 px-4 rounded-full"
                    >{{ numResto.celtispay }}</label
                  >
                  <svg
                    @click="copyToClipboard(numResto.celtispay)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-green-400 ml-4 mt-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
                    />
                  </svg>
                </div>
                <div
                  v-if="numResto.moovpay !== null"
                  class="col-span-8 sm:col-span-8 flex items-center"
                >
                  <BaseLabel value="Numéro MOOV" />
                  <label
                    class="block font-medium text-sm text-gray-700 mt-2 bg-gray-200 px-4 rounded-full"
                    >{{ numResto.moovpay }}</label
                  >
                  <svg
                    @click="copyToClipboard(numResto.moovpay)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-green-400 ml-4 mt-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
                    />
                  </svg>
                </div>
                <div
                  v-if="numResto.mtnpay !== null"
                  class="col-span-8 sm:col-span-8 flex items-center"
                >
                  <BaseLabel value="Numéro MTN" />
                  <label
                    class="block font-medium text-sm text-gray-700 mt-2 bg-gray-200 px-4 rounded-full"
                    >{{ numResto.mtnpay }}</label
                  >

                  <svg
                    @click="copyToClipboard(numResto.mtnpay)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-green-400 ml-4 mt-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
  </TheModal>
  <TheModal
    width="w-full md:w-2/3 lg:w-1/2"
    :is-open="showModalRestoNote"
    @close-modal="showModalRestoNote = false"
  >
    <template #header>Noter le restaurant</template>

    <template #body>
      
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              
              <div class="mt-9 bg-black h-22 rounded-lg">
                <div class="flex text-white mt-6 w-full">
                  <button
                    @click="NoteResto()"
                    :class="[
                      'items-center',
                      'w-1/2',
                      'transition',
                      'duration-300',
                      'hover:scale-105',
                      'transform',
                    ]"
                  >
                    <p>2</p>
                    <p>BOF</p>
                  </button>

                  <button
                    @click="NoteResto4()"
                    :class="[
                      'items-center',
                      'w-1/2',
                      'transition',
                      'duration-300',
                      'hover:scale-105',
                      'transform',
                    ]"
                  >
                    <p>4</p>
                    <p>OK</p>
                  </button>

                  <button
                    @click="NoteResto6()"
                    :class="[
                      'items-center',
                      'w-1/2',
                      'transition',
                      'duration-300',
                      'hover:scale-105',
                      'transform',
                    ]"
                  >
                    <p>6</p>
                    <p>BIEN</p>
                  </button>

                  <button
                    @click="NoteResto8()"
                    :class="[
                      'items-center',
                      'w-1/2',
                      'transition',
                      'duration-300',
                      'hover:scale-105',
                      'transform',
                    ]"
                  >
                    <p>8</p>
                    <p>SUPER</p>
                  </button>

                  <button
                    @click="NoteResto10()"
                    :class="[
                      'items-center',
                      'w-1/2',
                      'transition',
                      'duration-300',
                      'hover:scale-105',
                      'transform',
                    ]"
                  >
                    <p>10</p>
                    <p>Cool</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </template>
    
  </TheModal>
</template>

<script>
import axios from "axios";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from "kkiapay";
import AlertComponent from "../components/AlertComponent.vue";
import TheModal from "../components/TheModal.vue";
import BaseLabel from "../components/BaseLabel.vue";
import BaseInput from "../components/BaseInput.vue";
import AddModalFooter from "../components/AddModalFooter.vue";
export default {
  name: "App",
  components: {
    TheModal,
    BaseLabel,
    BaseInput,
    AddModalFooter,
    AlertComponent,
  },
  data() {
    return {
      addform: {
        place: "",
        name: "",
        description: "",
        user_id: "",
        contact: "",
        date: "",
        restaurant_id: "",
      },
      addforms: {
        repas_id: "",
        name: "",
        restaurant_id: "",
        contact: "",
        description: "",
        adresse: "",
        user_id: "",
        quantite: 1,
        status: "En attente",
        montant: 0,
      },
      sendform: {
        respas_id: "",
        content: "",
        user_id: "",
      },
      sendforms: {
        restaurant_id: "",
        content: "",
        user_id: "",
      },
      noteform: {
        restaurant_id: "",
        nbr_rate: 0,
        user_id: "",
      },
      currentImage: 0,
      restaurantId: "",
      restaurants: [],
      filteredRestaurants: [],
      showModalRepas: false,
      showModalReservations: false,
      filteredMenus: [],
      menus: [],
      showModalCommentaires: false,
      showModalRestoCommentaires: false,
      showAlert: false,
      commandeID: "",
      transationID: "",
      userID: "",
      numResto: "",
      showModalCommande: false,
      showModalRestoNote: false,
      statusResto:"",
      alert: {
        message: "",
      },
    };
  },
  mounted() {
    this.profile();
    this.restaurant();
    this.getMenus();
    this.restaurantId = this.$route.params.id;
    addKkiapayListener("success", this.successHandler);
  },
  beforeUnmount() {
    removeKkiapayListener("success", this.successHandler);
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("Numéro copié dans le presse-papiers!");
        })
        .catch((err) => {
          console.error("Erreur lors de la copie: ", err);
        });
    },
    CommentaireModal(id) {
      this.showModalCommentaires = !this.showModalCommentaires;
      this.sendform.respas_id = id;
    },
    CommentaireRestoModal(id) {
      this.showModalRestoCommentaires = !this.showModalRestoCommentaires;
      this.sendforms.restaurant_id = id;
    },
    NoteRestoModal(id) {
      this.showModalRestoNote = !this.showModalRestoNote;
      this.noteform.restaurant_id = id;
    },
   RepasModal(id, repasStatus) {
  this.statusResto = repasStatus;
  
  if (this.statusResto !== 'disponible') {
    this.showModalRepas = false;
        alert('Le restaurant est fermé');

  } else {
    this.showModalRepas = !this.showModalRepas;
    this.addforms.repas_id = id;
    this.filteredMenus = this.menus.filter(
      (repas) => repas.id === this.addforms.repas_id
    );
  }
}
,
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.userID = response.data.id;
          console.log(this.userID);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async commande() {
      try {
        this.filters = "";
        this.addforms.montant =
          this.filteredMenus[0].prix * this.addforms.quantite;
        this.addforms.restaurant_id = this.restaurantId;
        this.addforms.user_id = this.userID;
        const data = {
          name: this.addforms.name,
          description: this.addforms.description,
          contact: this.addforms.contact,
          adresse: this.addforms.adresse,
          montant: this.addforms.montant,
          status: this.addforms.status,
          restaurant_id: this.addforms.restaurant_id,
          user_id: this.addforms.user_id,
        };

        const response = await axios.post("/api/commandes", data);
        if (response.status == 201) {
          this.commandeID = response.data.data.id;
          this.showModalRepas = !this.showModalRepas;
          (this.numResto = response.data.data.restaurant),
            console.log(this.numResto);
          this.lignecommande();
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async lignecommande() {
      try {
        this.filters = "";
        this.addforms.montant =
          this.filteredMenus[0].prix * this.addforms.quantite;
        const datas = {
          quantite: this.addforms.quantite,
          repas_id: this.addforms.repas_id,
          commande_id: this.commandeID,
          montant: this.addforms.montant,
        };

        const response = await axios.post("/api/lignecommandes", datas);
        if (response.status == 201) {
          this.showModalCommande = true;
          //this.createTransaction();
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async payementmethod() {
      try {
        const data = {
          transationId: this.transationID,
          commande_id: this.commandeID,
        };

        const response = await axios.post("/api/payments", data);
        if (response.status == 201) {
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Votre commande a été enregistrée avec succès.",
            timeout: 5000,
          }).show();
        } else {
          this.showModalRepas = !this.showModalRepas;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async commentaire() {
      try {
        this.sendform.user_id = this.userID;
        const response = await axios.post(
          "/api/commentairerespas",
          this.sendform
        );
        if (response.status == 201) {
          this.showModalCommentaires = !this.showModalCommentaires;
          this.sendform = {};
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci pour votre commentaire",
            timeout: 5000,
          }).show();
        } else {
          this.showModalCommentaires = !this.showModalCommentaires;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async NoteResto() {
      try {
        this.noteform.nbr_rate = 2;
        this.noteform.user_id = this.userID;
        const response = await axios.post("/api/notes", this.noteform);
        if (response.status == 201) {
          this.showModalRestoNote = !this.showModalRestoNote;
          this.sendform = {};
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci pour votre note",
            timeout: 5000,
          }).show();
        } else {
          this.showModalRestoNote = !this.showModalRestoNote;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },

 async NoteResto4() {
      try {
        this.noteform.nbr_rate = 4;
        this.noteform.user_id = this.userID;
        const response = await axios.post("/api/notes", this.noteform);
        if (response.status == 201) {
          this.showModalRestoNote = !this.showModalRestoNote;
          this.sendform = {};
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci pour votre note",
            timeout: 5000,
          }).show();
        } else {
          this.showModalRestoNote = !this.showModalRestoNote;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
     async NoteResto6() {
      try {
        this.noteform.nbr_rate = 6;
        this.noteform.user_id = this.userID;
        const response = await axios.post("/api/notes", this.noteform);
        if (response.status == 201) {
          this.showModalRestoNote = !this.showModalRestoNote;
          this.sendform = {};
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci pour votre note",
            timeout: 5000,
          }).show();
        } else {
          this.showModalRestoNote = !this.showModalRestoNote;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
     async NoteResto8() {
      try {
        this.noteform.nbr_rate = 8;
        this.noteform.user_id = this.userID;
        const response = await axios.post("/api/notes", this.noteform);
        if (response.status == 201) {
          this.showModalRestoNote = !this.showModalRestoNote;
          this.sendform = {};
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci pour votre note",
            timeout: 5000,
          }).show();
        } else {
          this.showModalRestoNote = !this.showModalRestoNote;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
     async NoteResto10() {
      try {
        this.noteform.nbr_rate = 10;
        this.noteform.user_id = this.userID;
        const response = await axios.post("/api/notes", this.noteform);
        if (response.status == 201) {
          this.showModalRestoNote = !this.showModalRestoNote;
          this.sendform = {};
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci pour votre note",
            timeout: 5000,
          }).show();
        } else {
          this.showModalRestoNote = !this.showModalRestoNote;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async commentaireResto() {
      try {
        this.sendforms.user_id = this.userID;
        const response = await axios.post(
          "/api/commentairerestos",
          this.sendforms
        );
        if (response.status == 201) {
          this.showModalRestoCommentaires = !this.showModalRestoCommentaires;
          this.sendforms = {};
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci pour votre commentaire",
            timeout: 5000,
          }).show();
        } else {
          this.showModalRestoCommentaires = !this.showModalRestoCommentaires;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async restaurant() {
      try {
        const response = await axios.get("/api/restaurants");
        if (response.data) {
          this.restaurants = response.data.data;
          this.filteredRestaurants = this.restaurants.filter(
            (restaurant) => restaurant.id === this.restaurantId
          );
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async reservation() {
      try {
        this.addform.restaurant_id = this.restaurantId;
        this.addform.user_id = this.userID;
        const response = await axios.post("/api/reservations", this.addform);
        if (response.status == 201) {
          this.showModalReservations = !this.showModalReservations;
          this.addform = {};
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Votre reservation à été éffectuer avec succés",
            timeout: 5000,
          }).show();
        } else {
          this.showModalReservations = !this.showModalReservations;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async getMenus() {
      try {
        const response = await axios.get(
          `/api/repa?restaurant_id=${this.$route.params.id}`
        );
        if (response.data) {
          this.menus = response.data.data;
          console.log(this.menus);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async createTransaction() {
      this.addforms.montant =
        this.filteredMenus[0].prix * this.addforms.quantite;
      openKkiapayWidget({
        amount: this.addforms.montant,
        firstname: this.addforms.name,
        lastname: this.addforms.name,
        api_key: "361197d0e64411ec848227abfc492dc7",
        sandbox: true,
        phone: "61000000",
      });
    },
    successHandler(response) {
      this.transationID = response.transactionId;

      this.payementmethod();
    },
    statusClass(status) {
      return {
        'text-green': status === 'disponible',
        'text-red': status !== 'disponible'
      };
    },
  },
};
</script>

<style scoped>
.custom-background {
  /*background-image: url("~@/assets/la-cuisine.jpg");*/
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}
.text-green {
  color: green;
}
.text-red {
  color: red;
}
.ml-4 {
  margin-left: 1rem; /* Ajustez selon vos besoins */
}
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.cursor-pointer {
  cursor: pointer;
}
.ml-2 {
  margin-left: 0.5rem;
}
</style>
