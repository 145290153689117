// src/routes.js
import { createRouter, createWebHistory } from 'vue-router';
import loadingService from '@/services/loadingService';


// Importez les composants associés à chaque route
import Home from './views/Home.vue';
import Restaurant from './views/Restaurant.vue';
import Repas from './views/Repas.vue';
import Reservation from './views/Reservation.vue';
import Commande from './views/Commande.vue';
import RestaurantDetail from './views/RestaurantDetail.vue';
import RestaurantDash from './views/RestaurantDash.vue';
import CommandeDash from './views/CommandeDash.vue';
import CommandeDetail from './views/CommandeDetail.vue';
import CommandeLivraisonDetail from './views/CommandeLivraisonDetail.vue';
import LivraisonClient from './views/LivraisonClient.vue';
import CommentaireDash from './views/CommentaireDash.vue';
import CommandeClientDetail from './views/CommandeClientDetail.vue';
import LivraisonClientDetail from './views/LivraisonClientDetail.vue';
import ReservationClient from './views/ReservationClient.vue';
import CategoryDash from './views/CategoryDash.vue';
import LivreurDash from './views/LivreurDash.vue';
import RepasDash from './views/RepasDash.vue';
import MenuDash from './views/MenuDash.vue';
import Profilsgerale from './views/Profilsgerale.vue';
import ResetPassword from './views/ResetPassword.vue';
import LivraisonDash from './views/LivraisonDash.vue';
import LivraisonRestaurant from './views/LivraisonRestaurant.vue';
import ClientCommande from './views/ClientCommande.vue';
import RepasCommentaireDash from './views/RepasCommentaireDash.vue';
import ReservationDash from './views/ReservationDash.vue';
import Profils from './views/Profils.vue';
import Propos from './views/Propos.vue';
import LoginForm from './views/LoginForm.vue';
import RegisterFrom from './views/RegisterFrom.vue';
import DashLayout from './layouts/DashLayout.vue';
import ClientLayout from './layouts/ClientLayout.vue';
import LoginLayout from './layouts/LoginLayout.vue';
import RegisterLayout from './layouts/RegisterLayout.vue';



const routes = [
  { path: '/', component: Home },
  { path: '/restaurant', component: Restaurant },
  { path: '/propos', component: Propos },
  { path: '/repas', component: Repas },
  { path: '/reservation', component: Reservation },
  { path: '/commande', component: Commande },
  { path: '/profiles', component: Profilsgerale },
  {
    path: "/restaurantdetail/:id",
    component: RestaurantDetail,
  },
  {
    path: '/auth/login',
    component: LoginForm,
    meta: { layout: LoginLayout, public: false },
  },
  {
    path: '/auth/register',
    component: RegisterFrom,
    meta: { layout: RegisterLayout, public: false }
  },
  {
    path: "/restaurantdash",
    component: RestaurantDash,
    meta: { layout: DashLayout, public: false },
  },
 
  {
    path: "/commandeDash",
    component: CommandeDash,
    meta: { layout: DashLayout, public: false },
  },
  { path: '/auth/password/reset', component: ResetPassword, 
  meta: { layout: LoginLayout, public: false },
},
  {
    path: "/LivraisonClientDash",
    component: LivraisonClient,
    meta: { layout: ClientLayout, public: false },
  },
  {
    path: "/commandeClientDash",
    component: ClientCommande,
    meta: { layout: ClientLayout, public: false },
  },
  {
    path: "/reservationClientDash",
    component: ReservationClient,
    meta: { layout: ClientLayout, public: false },
  },
  {
    path: "/commandeDetail/:id",
    component: CommandeDetail,
    meta: { layout: DashLayout, public: false },
  },
  {
    path: "/commandeLivraisonDetail/:id",
    component: CommandeLivraisonDetail,
    meta: { layout: DashLayout, public: false },
  },
  {
    path: "/commandeClientDetail/:id",
    component: CommandeClientDetail,
    meta: { layout: ClientLayout, public: false },
  },
  {
    path: "/LivraisonClientDetail/:id",
    component: LivraisonClientDetail,
    meta: { layout: ClientLayout, public: false },
  },
  {
    path: "/categorys",
    component: CategoryDash,
    meta: { layout: DashLayout, public: false },
  },
  {
    path: "/livreurDash",
    component: LivreurDash,
    meta: { layout: DashLayout, public: false },
  },
  {
    path: "/repasdash",
    component: RepasDash,
    meta: { layout: DashLayout, public: false },
  },
  {
    path: "/commentairedash",
    component: CommentaireDash,
    meta: { layout: DashLayout, public: false },
  },
  {
    path: "/repascommentairedash/:id",
    component: RepasCommentaireDash,
    meta: { layout: DashLayout, public: false },
  },
  {
  path: "/menudash",
  component: MenuDash,
  meta: { layout: DashLayout, public: false },
},
  {
    path: "/livraisonDash",
    component: LivraisonDash,
    meta: { layout: DashLayout, public: false },
  },
  {
    path: "/livraisonResto",
    component: LivraisonRestaurant,
    meta: { layout: DashLayout, public: false },
  },
  {
    path: "/reservationDash",
    component: ReservationDash,
    meta: { layout: DashLayout, public: false },
  },
  {
    path: "/profils",
    component: Profils,
    meta: { layout: DashLayout, public: false },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
    
  loadingService.setLoading(true);
  setTimeout(() => {
    loadingService.setLoading(false);
  }, 3000);
  next();
});
export default router;