<template>
  <div class="flex ">
    <!-- Barre latérale -->
    <div class="w-1/7 shadow-2xl bg-gray-800 text-white">
      <!-- Logo du tableau de bord -->
      <div class="flex items-center justify-between">
        <router-link class="flex items-center" to="/">
          <img
            src="../assets/WADOUNNOU.png"
            alt="Logo"
            class="h-24 mt-4 w-auto"
          />
        </router-link>
        <div @click="isOpen = !isOpen" class="flex md:hidden">
          <button
            type="button"
            class="text-gray-800 hover:text-gray-400 focus:outline-none focus:text-gray-400"
            aria-label="toggle menu"
          >
            <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current bg-white">
              <path
                fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div
        :class="isOpen ? 'flex' : 'hidden'"
        class="flex-col mt-8 space-y-4 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0"
      >
        <!-- Menu de la barre latérale -->
        <ul  class="py-4">
         
          <!--<li class="px-4 py-2 hover:bg-gray-600">
            <router-link
              class="text-slate-950 hover:text-gray-300"
              active-class="text-green-600"
              to="/categorys"
              >Categories</router-link
            >
          </li>-->
           <li class="px-4 py-2 hover:bg-gray-600">
            <router-link
              class="text-slate-950 hover:text-gray-300"
              active-class="text-green-600"
              to="/commandeClientDash"
              >Mes Commandes</router-link
            >
          </li>
              <h1 class="text-4xl font-bold border border-red-200 mt-4"></h1>
           <li class="px-4 py-2 hover:bg-gray-600">
            <router-link
              class="text-slate-950 hover:text-gray-300"
              active-class="text-green-600"
              to="/LivraisonClientDash"
              >Livraisons</router-link
            >
          </li>
          <li class="px-4 py-2 hover:bg-gray-600">
            <router-link
              class="text-slate-950 hover:text-gray-300"
              active-class="text-green-600"
              to="/reservationClientDash"
              >Reservation</router-link
            >
          </li>
            <!--<h1 class="text-4xl font-bold border border-red-200 mt-4"></h1>
          <li class="px-4 py-2 hover:bg-gray-600">
            <router-link
              class="text-slate-950 hover:text-gray-300"
              active-class="text-green-600"
              to="/reservationDash"
              >Mes reservations</router-link
            >
          </li>-->
         

      
          
         
          <h1 class="text-4xl font-bold border border-red-200 mt-4"></h1>
          <li class="px-4 py-2 hover:bg-gray-600">
            <router-link
              class="text-slate-950 hover:text-gray-300"
              active-class="text-green-600"
              to="/"
              >Aller au site</router-link
            >
          </li>
        </ul>
       
      </div>
    </div>

    <!-- Contenu du tableau de bord -->
    <div class="w-full p-4">
      <slot />
    </div>
  </div>
</template>

<script>
//import axios from "axios";
export default {
  name: "Dashboard",

  data() {
    return {
      user: "",
      role_id: "",
      isOpen: false,
    };
  },
  created() {
    //this.profile();
    //this.role();
  },
  methods: {
  
  },
};
</script>
