<template>
  <div
    class="relative overflow-x-auto shadow-md sm:rounded-lg bg-white mt-9 p-4"
  >
   
    <div >
      <div class="flex items-center justify-between pb-4">
        <div v-show="showAlert">
          <AlertComponent :content="alert.message" type-alert="error" />
        </div>
        <label for="table-search" class="sr-only">Rechercher</label>
        <div class="relative">
          <div
            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
          >
            <svg
              class="w-5 h-5 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            type="text"
            id="table-search"
            wire:model="search"
            class="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Rechercher ..."
          />
        </div>
        <div class="ml-9 flex text-white">
      <button
     
       class=" bg-blue-500 rounded-lg font-medium px-6 py-1"
         @click="filter = ''"
       >
        Tous
      </button>
      <button 
      @click="filter = 'En cours'"
      class=" bg-yellow-300 rounded-lg font-medium px-6 py-1 ml-4">
        En cours
      </button>
      <button
      @click="filter = 'suspended'"
       class=" bg-red-500 rounded-lg font-medium px-6 py-1 ml-4">
        Suspendue
      </button>
      <button 
      @click="filter = 'Terminer'"
       class="bg-green-500  rounded-lg font-medium px-6 py-1 ml-4">
       Terminer
      </button>
      </div>
      </div>
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead
          class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-6 py-3">Nom</th>
            <th scope="col" class="px-6 py-3">Address</th>
            <th scope="col" class="px-6 py-3">Phone</th>
            <th scope="col" class="px-6 py-3">Nom restaurant</th>
            <th scope="col" class="px-6 py-3">Statut</th>
            <th scope="col" class="px-6 py-3">Nom livreur</th>
            <th scope="col" class="px-6 py-3">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(livraison, index) in sortedlivraisons "
            :key="index"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
          >
            <th
              scope="row"
              class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              {{ livraison.commande.name }}
            </th>
            <td class="px-6 py-4">{{ livraison.commande.adresse }}</td>
            <td class="px-6 py-4">{{ livraison.commande.contact }}</td>
            <td class="px-6 py-4">{{ livraison.commande.restaurant.name }}</td>

            <td class="px-6 py-4">
              <div
                v-if="livraison.status === 'En cours'"
                class="whitespace-nowrap w-24 text-center px-4 rounded-full  py-1 text-sm bg-yellow-300 text-gray-900"
              >
               
                {{ livraison.status }}
              </div>
              <div
                v-if="livraison.status === 'suspended'"
                class="whitespace-nowrap w-24 text-center px-4 rounded-full  py-1 text-sm  bg-red-500 text-gray-900"
              >
                
                {{ livraison.status }}</div
              >
              <div
                v-if="livraison.status === 'Terminer'"
                class="whitespace-nowrap w-24 text-center px-4 rounded-full  py-1 text-sm bg-green-500 text-gray-900"
              >
                
                {{ livraison.status }}
              </div>
            </td>
            <td class="px-6 py-4">{{ livraison.livreur.name }}</td>
           <!-- <td class="flex items-center px-6 py-4 space-x-3">
              <label
                class="flex items-center relative w-max cursor-pointer select-none"
              >
                <input
                  type="checkbox"
                  class="appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-red-500"
                />

                <span
                  class="w-7 h-7 right-7 absolute rounded-full transform transition-transform bg-gray-200"
                />
              </label>
            </td>-->
          
           <td 
             class="flex items-center px-6 py-4 space-x-3">
              <router-link
            
              class="text-green-500 hover:bg-gray-100 hover:rounded-lg font-medium"
              :to="`/LivraisonClientDetail/${livraison.commande.id}`"
            >
              <span class="flex items-center p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                  />
                </svg>
                Voir
              </span>
            </router-link>
           
          
          </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <DeleteModalFooter
    width="w-full md:w-2/3 lg:w-1/3"
    :is-open="showDeleteLivraisonModal"
    @cancel="showDeleteLivraisonModal = !showDeleteLivraisonModal"
    @delete="deleteRestaurant()"
  >
    <template #header>Supprimer</template>
    <template #body> Vous voulez suppprimer cet livraison </template>
  </DeleteModalFooter>
  <TheModal
    width="w-full md:w-2/3 lg:w-1/2"
    :is-open="showModalLivraison"
    @close-modal="showModalLivraison = false"
  >
    <template #header> Ajouter une Livraison</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="livraison()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div class="col-span-8 sm:col-span-4">
                  <BaseLabel value="Nom " />
                  <BaseInput id="nom" v-model="addform.name" class="mt-2" />
                </div>
                <div class="col-span-8 sm:col-span-4">
                  <BaseLabel value="Adresse" />
                  <BaseInput
                    id="prenom"
                    v-model="addform.addrese"
                    class="mt-2"
                  />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Télephone" />
                  <div class="relative mt-1">
                    <BaseInput
                      v-model="addform.phone"
                      placeholder="62333333"
                      class="mt-2"
                    />
                  </div>
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Description" />
                  <BaseInput
                    id="language"
                    v-model="addform.description"
                    class="mt-2"
                  />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Status" />

                  <select
                    id="compagnie"
                    v-model="addform.status"
                    class="rounded border border-gray-300 mt-2 focus:ring-blue-500 focus:border-blue-500 block w-full p-2 px-9"
                    placeholder="status"
                  >
                    <option value="En cours">En cours</option>
                    <option value="suspended">Suspend</option>
                    <option value="Terminer">Terminer</option>
                  </select>
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Choisissez la commande" />
                  <select
                    name="restaurant"
                    id="restaurant"
                    v-model="addform.commande_id"
                    class="block w-full p-2 border mt-2 border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
                  >
                    <option
                      v-for="(commande, index) in commandes"
                      :key="index"
                      :value="commande.id"
                    >
                      {{ commande.name }}
                    </option>
                    <!-- Ajoutez plus d'options au besoin -->
                  </select>
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Choisissez le livreur" />
                  <select
                    name="restaurant"
                    id="restaurant"
                    v-model="addform.livreur_id"
                    class="block w-full p-2 border mt-2 border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
                  >
                    <option
                      v-for="(livreur, index) in livreurs"
                      :key="index"
                      :value="livreur.id"
                    >
                      {{ livreur.name }}
                    </option>
                    <!-- Ajoutez plus d'options au besoin -->
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <AddModalFooter
        @cancel="showModalLivraison = false"
        @send="livraison()"
      />
    </template>
  </TheModal>
  <TheModal
    width="w-full md:w-2/3 lg:w-1/2"
    :is-open="showModalLivraisonUpdate"
    @close-modal="showModalLivraisonUpdate = false"
  >
    <template #header> Mettre à jour une Livraison</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="LivreCommande()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div class="col-span-8 sm:col-span-8">
                  
                  <div class="mt-2"> 
                  <input
                    type="radio"
                    name="livraisonDetail"
                    id="livraisonDetailEnCours"
                    value="suspended"
                    v-model="sendform.status"
                  />
                  <label for="livraisonDetailEnCours" class="ml-1 text-xl font-blod">Suspendre</label>

                  <input
                    type="radio"
                    class="ml-24 "
                    name="livraisonDetail"
                    id="livraisonDetailTerminer"
                    value="Terminer"
                    v-model="sendform.status"
                    checked
                  />
                  <label for="livraisonDetailTerminer" class="ml-1 text-xl font-blod" >Terminé</label>
                   </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <AddModalFooter
        @cancel="showModalLivraisonUpdate = false"
        @send="LivreCommande()"
      />
    </template>
  </TheModal>
</template>

<script>
import axios from "axios";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import DeleteModalFooter from "../components/DeleteModalFooter.vue";
import TheModal from "../components/TheModal.vue";
import BaseLabel from "../components/BaseLabel.vue";
import BaseInput from "../components/BaseInput.vue";
import AddModalFooter from "../components/AddModalFooter.vue";
import AlertComponent from "../components/AlertComponent.vue";
export default {
  name: "LivraisonDash",
  components: {
    AlertComponent,
    DeleteModalFooter,
    TheModal,
    BaseLabel,
    BaseInput,
    AddModalFooter,
  },
  data() {
    return {
      addform: {
        name: "",
        livreur_id: "",
        address: "",
        commande_id: "",
        description: "",
        phone: "",
        status: "",
      },
       sendform: {
       
        status: "Terminer",
      },
       sendforms: {
       
        status: "",
      },
        sendformupdate: {
       
        status: "disponible",
      },
      showAlert: false,
      alert: {
        message: "",
      },
      processing: false,
      showDeleteLivraisonModal: false,
      showModalLivraison: false,
      showModalLivraisonUpdate: false,
      user: "",
      livraisons: [],
      commandes: [],
      livreurs: [],
      filter: "",
      livraisonID:"",
      livreurID:"",
      commandeID:"",
      livreurIDs:"",
    };
  },
  created() {
    this.profile();
    //this.getLivraison();
    this.getCommande();
    //this.getLivreur();
  },
  computed: {
    filteredLivreur() {
      const searchTerm = this.filter.toLowerCase();
      const filtered_data = this.livreurs.filter((livreurs) => {
        const name = livreurs.user.id.toLowerCase();
        return name.includes(searchTerm);
      });

      return filtered_data;
    },

    sortedlivraisons() {
      // Triez les vidéos par date de création dans l'ordre décroissant
      return this.livraisons
        .slice()
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
    },
    filteredLivraisons() {
      // Vérifiez si filteredRestaurant n'est pas vide
      if (this.filteredLivreur.length > 0) {
        // Sélectionnez le premier élément de filteredRestaurant
        const selectedRestaurant = this.filteredLivreur[0];

        // Assurez-vous que selectedRestaurant et son id sont définis avant de les utiliser
        const searchTerm = selectedRestaurant.id.toLowerCase();
        // Filtrez les menus basés sur l'ID du restaurant si celui-ci est défini
        const filtered_data = this.livraisons.filter((livraisons) => {
          const restaurantId = livraisons.livreur.id.toLowerCase();
          return restaurantId.includes(searchTerm);
        });

        return filtered_data;
      } else {
        // Retournez tous les menus si filteredRestaurant est vide
        return this.livraisons;
      }
    },
  },
  methods: {
    deleteLivraisonModal() {
      this.showDeleteLivraisonModal = !this.showDeleteLivraisonModal;
    },
    LivreCommandeModal(id, commandeid) {
      this.showModalLivraisonUpdate = !this.showModalLivraisonUpdate;
      this.livraisonID = id;
      this.commandeID = commandeid;
      
    },
    async LivreCommande() {
      try {
        this.isLoading = true;
        this.showModalLivraisonUpdate = false;
        const response = await axios.put(`/api/livraisons/${this.livraisonID}`, this.sendform);
        if (response.status == 200) {
          this.isLoading = false;
          this.livreurIDs = response.data.data.livreur.id;
          console.log(this.livreurIDs);
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci pour la livraison de la commande",
            timeout: 5000,
          }).show();
          //this.LivreurDispo(this.livreurIDs);
          if (this.sendform.status ==="Terminer") {
            this.sendforms.status ="livrer",
            this.ValideCommande(this.commandeID);
          } else {
            this.sendforms.status ="non livrer",
            this.ValideCommande(this.commandeID);
            
          }
           this.getLivraison();
        } else {
          this.showAlert = true;
          this.isLoading = false;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.isLoading = false;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
        async ValideCommande(id) {
      try {
        
        
        const response = await axios.post(`/api/commandes/${id}`, this.sendforms);
        if (response.status == 200) {
        
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci le restaurant est informé de la livraison",
            timeout: 5000,
          }).show();
           this.profile();
        } else {
          this.showAlert = true;
          this.isLoading = false;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.isLoading = false;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
      async LivreurDispo(id) {
      try {
        
        
        const response = await axios.put(`/api/livreurs/${id}`, this.sendformupdate);
        if (response.status == 200) {
        
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci pour votre affectations de la commande",
            timeout: 5000,
          }).show();
           this.getCommande();
        } else {
          this.showAlert = true;
          this.isLoading = false;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.isLoading = false;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.user = response.data.id;
          this.filter = response.data.id;
          this.getLivraison(this.user)
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async getLivraison(id) {
      try {
        const response = await axios.get(`/api/livraisoncommandeuser?user_id=${id}`);
        if (response.data) {
          this.livraisons = response.data.data;
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async getLivreur(id) {
      try {
        const response = await axios.get(`/api/livreur?user_id=${id}`);
        if (response.data) {
          this.livreurs = response.data.data;
          this.livreurID = this.livreurs[0].id
          this.getLivraison(this.livreurID)
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async getCommande() {
      try {
        const response = await axios.get("/api/commandes");
        if (response.data) {
          this.commandes = response.data.data;
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async livraison() {
      try {
        const response = await axios.post("/api/livraisons", this.addform);
        if (response.status == 201) {
          this.showModalLivraison = !this.showModalLivraison;
          this.addform = {};
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Votre commande est affecter au livreur avec succés",
            timeout: 5000,
          }).show();
          this.getLivraison();
        } else {
          this.showModalLivraison = !this.showModalLivraison;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
  },
};
</script>
<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

input:checked {
  background-color: #22c55e; /* bg-green-500 */
}

input:checked ~ span:last-child {
  --tw-translate-x: 1.75rem; /* translate-x-7 */
}
</style>