<template>
  <nav
    class="fixed top-0 inset-x-0 px-6 lg:py-2 shadow-sm mx-auto md:flex md:justify-between md:items-center z-50 bg-white"
  >
    <div class="flex items-center justify-between">
      <div class="lg:mb-4 md:mb-0">
        <img src="../assets/WADOUNNOU.png" alt="Logo" class="h-12 w-auto" />
      </div>
      <!-- Mobile menu button -->
      <div @click="isOpen = !isOpen" class="flex md:hidden">
        <button
          type="button"
          class="text-gray-800 hover:text-gray-400 focus:outline-none focus:text-gray-400"
          aria-label="toggle menu"
        >
          <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
            <path
              fill-rule="evenodd"
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
            ></path>
          </svg>
        </button>
      </div>
    </div>

    <div
      :class="isOpen ? 'flex' : 'hidden'"
      class="w-52 absolute z-20 bg-gray-700 top-12 ml-44"
    >
      <div class="">
        <button
          @click="isOpen = !isOpen"
          class="p-2 text-white text-lg font-bold"
        >
          &#9747;
        </button>
      </div>

      <ul class="list-none text-white ml-6 space-y-4 mt-4">
        <li>
          <router-link
            class="text-slate-950 hover:text-gray-500"
            active-class="text-green-600"
            @click="isOpen = !isOpen"
            to="/"
            >Acceuil</router-link
          >
        </li>
        <li>
          <router-link
            class="text-slate-950 hover:text-gray-500"
            active-class="text-green-600"
            @click="isOpen = !isOpen"
            to="/commande"
            >Commande</router-link
          >
        </li>
        <li>
          <router-link
            class="text-slate-950 hover:text-gray-500"
            active-class="text-green-600"
            @click="isOpen = !isOpen"
            to="/reservation"
            >Reservation</router-link
          >
        </li>
        <li>
          <router-link
            class="text-slate-950 hover:text-gray-500"
            active-class="text-green-600"
            @click="isOpen = !isOpen"
            to="/restaurant"
            >Restaurants</router-link
          >
        </li>
        <li>
          <router-link
            class="text-slate-950 hover:text-gray-500"
            active-class="text-green-600"
            @click="isOpen = !isOpen"
            to="/repas"
            >Repas</router-link
          >
        </li>
        <li>
          <router-link
            class="text-slate-950 hover:text-gray-500"
            active-class="text-green-600"
            @click="isOpen = !isOpen"
            to="/propos"
            >A propos</router-link
          >
        </li>
        <li class="my-4">
          <router-link
            to="/auth/login"
            v-if="this.user === ''"
            class="text-white border border-gray-300 whitespace-nowrap rounded-full py-1 px-4 hover:bg-white hover:text-gray-900 transition duration-300 inline"
          >
            Se connecter
          </router-link>
          <div v-else>
            <div class="relative ml-4 mb-4" @click="toggleDropdown">
              <div>
                <button
                  type="button"
                  class="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  id="user-menu-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  <span class="absolute -inset-1.5"></span>
                  <span class="sr-only">Open user menu</span>
                  <div v-if="photo_url === null">
                    <img
                      class="h-12 w-12 rounded-full"
                      src="../assets/c972ce3d5e2f4ea3d8d0b83ef3423dd1.png"
                      alt=""
                    />
                  </div>
                  <div v-else>
                    <img
                      class="h-12 w-12 rounded-full"
                      :src="photo_url"
                      alt=""
                    />
                  </div>
                </button>
              </div>

              <!-- Dropdown menu -->
              <div
                v-show="isDropdownOpen"
                class="absolute right-0 z-10 mt-2 origin-top-right w-44 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
                tabindex="-1"
              >
                <!-- Active: "bg-gray-100", Not Active: "" -->
                <span
                  class="block px-4 py-2 text-sm text-gray-700 font-bold"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-0"
                  >{{ this.name }}</span
                >
                <router-link
                  class="block flex text-sm text-gray-700 font-bold ml-12 mt-2"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-1"
                  to="/profiles"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                  <span class="ml-2">Profils</span></router-link
                >
                <div
                  v-if="this.role_id === 'c0c201a0-699a-4d9e-9baa-48cc8a84ff94'"
                >
                  <router-link
                    to="restaurantdash"
                    class="block px-4 py-2 text-sm text-gray-700 font-bold"
                    role="menuitem"
                    tabindex="-1"
                    id="user-menu-item-1"
                    >DashBoard</router-link
                  >
                </div>
                <div
                  v-if="this.role_id === 'd3172d87-82dd-4e95-9d03-1f7f1d555d0e'"
                >
                  <router-link
                    to="livraisonDash"
                    class="block px-4 py-2 text-sm text-gray-700 font-bold"
                    role="menuitem"
                    tabindex="-1"
                    id="user-menu-item-1"
                    >DashBoard</router-link
                  >
                </div>
                <router-link
                  to="/commandeClientDash"
                  class="block px-4 py-2 text-sm text-gray-700 font-bold"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-1"
                  >Mes Opérations</router-link
                >
                <button
                  @click="logout()"
                  class="block px-4 py-2 text-sm text-gray-700 font-bold ml-9"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-2"
                >
                  Déconnexion
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      class="flex-col mt-8 space-y-2 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0 hidden md:block"
    >
      <ul class="lg:flex space-x-6 text-xl font-bold">
        <li @click="isDropdownOpen = false">
          <router-link
            class="text-slate-950 hover:text-gray-500"
            active-class="text-green-600"
            to="/"
            >Acceuil</router-link
          >
        </li>
        <li @click="isDropdownOpen = false">
          <router-link
            class="text-slate-950 hover:text-gray-500"
            active-class="text-green-600"
            to="/commande"
            >Commande</router-link
          >
        </li>
        <li @click="isDropdownOpen = false">
          <router-link
            class="text-slate-950 hover:text-gray-500"
            active-class="text-green-600"
            to="/reservation"
            >Reservation</router-link
          >
        </li>
        <li @click="isDropdownOpen = false">
          <router-link
            class="text-slate-950 hover:text-gray-500"
            active-class="text-green-600"
            to="/restaurant"
            >Restaurants</router-link
          >
        </li>
        <li @click="isDropdownOpen = false">
          <router-link
            class="text-slate-950 hover:text-gray-500"
            active-class="text-green-600"
            to="/repas"
            >Repas</router-link
          >
        </li>
        <li @click="isDropdownOpen = false">
          <router-link
            class="text-slate-950 hover:text-gray-500"
            active-class="text-green-600"
            to="/propos"
            >A propos</router-link
          >
        </li>
      </ul>
      <div
        v-if="this.user === ''"
        class="flex items-center lg:justify-end space-x-6 text-xl font-bold ml-16 lg:ml-0"
      >
        <router-link
          class="bg-green-600 px-6 py-3 text-white poppins rounded-full ring-red-300 focus:outline-none focus:ring-4 transform transition duration-700 hover:scale-105"
          to="/auth/login"
        >
          Se connecter
        </router-link>
      </div>
      <div v-else>
        <div class="relative" @click="toggleDropdown">
          <div>
            <button
              type="button"
              class="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
              id="user-menu-button"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <span class="absolute -inset-1.5"></span>
              <span class="sr-only">Open user menu</span>
              <div v-if="photo_url === null">
                <img
                  class="h-12 w-12 rounded-full"
                  src="../assets/c972ce3d5e2f4ea3d8d0b83ef3423dd1.png"
                  alt=""
                />
              </div>
              <div v-else>
                <img class="h-12 w-12 rounded-full" :src="photo_url" alt="" />
              </div>
            </button>
          </div>

          <!-- Dropdown menu -->
          <div
            v-show="isDropdownOpen"
            class="absolute right-0 z-10 mt-2 origin-top-right w-44 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabindex="-1"
          >
            <!-- Active: "bg-gray-100", Not Active: "" -->
            <span
              class="block px-4 py-2 text-sm text-gray-700 font-bold"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-0"
              >{{ this.name }}</span
            >
            <router-link
              class="block flex text-sm text-gray-700 font-bold ml-12 mt-2"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-1"
              to="/profiles"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
              <span class="ml-2">Profils</span></router-link
            >
            <div v-if="this.role_id === 'c0c201a0-699a-4d9e-9baa-48cc8a84ff94'">
              <router-link
                to="restaurantdash"
                class="block px-4 py-2 text-sm text-gray-700 font-bold"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-1"
                >DashBoard</router-link
              >
            </div>
            <div v-if="this.role_id === 'd3172d87-82dd-4e95-9d03-1f7f1d555d0e'">
              <router-link
                to="livraisonDash"
                class="block px-4 py-2 text-sm text-gray-700 font-bold"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-1"
                >DashBoard</router-link
              >
            </div>
            <router-link
              to="/commandeClientDash"
              class="block px-4 py-2 text-sm text-gray-700 font-bold"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-1"
              >Mes Opérations</router-link
            >
            <button
              @click="logout()"
              class="block px-4 py-2 text-sm text-gray-700 font-bold ml-9"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-2"
            >
              Déconnexion
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from "axios";
import TokenService from "../services/storage.service";
export default {
  name: "Navbar",
  data() {
    return {
      user: "",
      name: "",
      role_id: "",
      photo_url: "",

      isDropdownOpen: false,
      isOpen: false,
      isconnetid: false,
    };
  },
  created() {
    this.profile();
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async logout() {
      try {
        const response = await axios.get("/api/logout");
        if (response.status === 204) {
          TokenService.removeToken();
          this.user = "";
        }
      } catch (error) {
        console.log(error);
      }
    },
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.name = response.data.name;
          this.user = response.data.id;
          this.role_id = response.data.role_id;
          this.photo_url = response.data.profile_photo_path;
          console.log(this.photo_url);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
  },
};
</script>
