<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="">
    <div class="lg:mt-20 mt-12">
      <img class="w-full" src="../assets/rservation.jpg" alt="Image 2" />
    </div>
    <div v-show="showAlert">
      <AlertComponent :content="alert.message" type-alert="error" />
    </div>

    <!-- component -->

    <!-- <label for="restaurant" class="mb-3 block text-2xl font-bold mt-6">
      Recherchez un restaurant
    </label>
    <div
      class="rounded-full p-1 mt-4 box-border border border-orange-500 mx-auto my-auto bg-white overflow-hidden ring-red-300 focus:ring-4 w-2/4 flex items-center py-1"
    >
      <input
        v-model="filter"
        type="text"
        class="rounded-full px-4 focus:outline-none w-full"
        placeholder="Recherche ......."
      />
      <button
        class="text-sm bg-green-600 py-2 px-6 rounded-full text-white poppins ring-red-300 focus:ring-4 transition duration-300 hover:scale-105 transform"
      >
        Search
      </button>
    </div> -->
     <div class="shadow-sm bg-gray-100 lg:hidden">
          
          <div
            v-for="(restaurant, index) in filteredRestaurant"
            :key="index"
            class="shadow-xl mt-2 mx-2 p-2"
          >
          <label for="lName" class="mb-3 block lg:text-2xl text-lg font-bold">
            Restaurant selectionnez
          </label>
            <img
              class=" mt-2 w-full"
              :src="restaurant.image_url"
              alt="{title}"
            />
            <div class="lg:ml-2 mt-6 text-left ml-9">
              <h2 class="text-2xl font-semibold mb-2">{{ restaurant.name }}</h2>
              <p class="text-gray-600 text-sm mb-2 mt-2 mr-2 hidden md:block">
                {{ restaurant.description }}
              </p>
              <p class="text-gray-600 text-sm mb-2">
                A {{ restaurant.adresse }} Contactez au {{ restaurant.phone }}
              </p>
              <p class="text-gray-600 text-sm mb-2">
                Spécialité {{ restaurant.specilite }} a une capacité de
                {{ restaurant.capacite }} place
              </p>
              <p class="text-gray-600 text-sm mb-2">
                Ouvre à {{ restaurant.heure_douverture }} et ferme à
                {{ restaurant.heure_fermeture }}
              </p>
            </div>
          </div>
        </div>
    <div class="lg:flex items-center justify-center lg:p-12 p-2">
      <!-- Author: FormBold Team -->
      <!-- Learn More: https://formbold.com -->

      <div class="lg:flex w-full lg:max-w-[550px]">
        <form
          action="#"
          method="POST"
          @submit.prevent="!passwordErrorMessage && reservation()"
          class="lg:w-3/4 lg:border border-gray-500 mr-2"
        >
          <div class="lg:ml-24 lg:mr-9">
            <div class="mb-5">
              <label
                for="lName"
                class="mb-3 block lg:text-2xl text-lg font-bold"
              >
                Tapez la ville
              </label>
              <input
                type="text"
                name="lName"
                id="lName"
                v-model="filters"
                placeholder="ville"
                class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
          </div>
          <div class="mb-5 lg:ml-24 lg:mr-9">
            <label
              for="restaurant"
              class="mb-3 block lg:text-2xl text-lg font-bold"
            >
              Choisissez un restaurant
            </label>
            <div class="flex flex-col md:flex-row gap-3">
              <select
                name="restaurant"
                id="restaurant"
                v-model="addform.restaurant_id"
                @change="search"
                class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              >
                <option value="">Tous</option>
                <option
                  v-for="(restaurant, index) in filteredRestaurants"
                  :key="index"
                  :value="restaurant.id"
                >
                  {{ restaurant.name }}
                </option>
                <!-- Ajoutez plus d'options au besoin -->
              </select>
              <div class="flex">
                <input
                  type="text"
                  v-model="filter"
                  placeholder="Rechercher un restaurant"
                  class="w-full rounded-l-lg border border-gray-400 bg-white py-3 px-6"
                />
                <button
                  type="submit"
                  class="bg-green-600 text-white rounded-r px-2 md:px-3 py-0 md:py-1"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div class="mb-5 lg:ml-24 lg:mr-9">
            <label for="guest" class="mb-3 block lg:text-2xl text-lg font-bold">
              Nombre de places
            </label>
            <input
              type="number"
              name="guest"
              id="guest"
              v-model="addform.place"
              placeholder="1"
              required
              min="0"
              class="w-full appearance-none rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            />
          </div>
          <div v-show="alert.type !== ''" class="my-2">
            <AlertComponent :content="alert.message" :type-alert="alert.type" />
          </div>
          <div class="lg:ml-24 lg:mr-9">
            <div class="mb-5">
              <label
                for="lName"
                class="mb-3 block lg:text-2xl text-lg font-bold"
              >
                Informations Complémentaires
              </label>
              <input
                type="text"
                name="lName"
                id="lName"
                v-model="addform.description"
                placeholder="Decription"
                class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
          </div>
          <div class="lg:ml-24 lg:mr-9 flex flex-wrap">
            <div class="w-full sm:w-1/2">
              <div class="mb-5">
                <label
                  for="fName"
                  class="mb-3 block lg:text-2xl text-lg font-bold"
                >
                  Nom Complet
                </label>
                <input
                  type="text"
                  name="name"
                  id="fName"
                  v-model="addform.name"
                  required
                  placeholder=" Nom Complet"
                  class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
              </div>
            </div>
            <div class="w-full sm:w-1/2">
              <div class="mb-5 ml-2">
                <label
                  for="lName"
                  class="mb-3 block lg:text-2xl text-lg font-bold"
                >
                  Contact
                </label>
                <input
                  type="text"
                  name="lName"
                  id="lName"
                  v-model="addform.contact"
                  placeholder="61000000"
                  @input="validatePassword"
                  required
                  class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
              </div>
              <div class="text-red-500" v-if="passwordErrorMessage">
                {{ passwordErrorMessage }}
              </div>
            </div>
          </div>

          <div class="lg:ml-24 lg:mr-9">
            <div class="mb-5">
              <label
                for="date"
                class="mb-3 block lg:text-2xl text-lg font-bold"
              >
                Date et Heure
              </label>
              <input
                type="datetime-local"
                name="date"
                id="date"
                v-model="addform.date"
                required
                class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
          </div>
          
          <div class="flex justify-center">
            <button
              class="hover:shadow-form mr-2 rounded-md border border-gray-200 py-3 px-8 text-center text-base font-semibold text-black outline-none"
              type="button"
              @click="reset()"
            >
              Annuler
            </button>
            <button
              class="hover:shadow-form ml-2 rounded-md bg-green-400 py-3 px-8 text-center text-base font-semibold text-white outline-none"
            >
              <span v-if="isLoading">
                <spiner />
              </span>
              <span v-else>valider</span>
            </button>
          </div>
          <div class="h-4"></div>
        </form>
        <div class="w-1/4 shadow-sm bg-gray-100 hidden md:block">
          <label for="lName" class="mb-3 block lg:text-2xl text-lg font-bold">
            Restaurant selectionnez
          </label>
          <div
            v-for="(restaurant, index) in filteredRestaurant"
            :key="index"
            class="shadow-xl mt-2 mx-2 p-2"
          >
            <img
              class="transform transition duration-700 hover:scale-125 mt-2 w-full"
              :src="restaurant.image_url"
              alt="{title}"
            />
            <div class="lg:ml-2 mt-6 text-left ml-9">
              <h2 class="text-2xl font-semibold mb-2">{{ restaurant.name }}</h2>
              <p class="text-gray-600 text-sm mb-2 mt-2 mr-2 hidden md:block">
                {{ restaurant.description }}
              </p>
              <p class="text-gray-600 text-sm mb-2">
                A {{ restaurant.adresse }} Contactez au {{ restaurant.phone }}
              </p>
              <p class="text-gray-600 text-sm mb-2">
                Spécialité {{ restaurant.specilite }} a une capacité de
                {{ restaurant.capacite }} place
              </p>
              <p class="text-gray-600 text-sm mb-2">
                Ouvre à {{ restaurant.heure_douverture }} et ferme à
                {{ restaurant.heure_fermeture }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import AlertComponent from "../components/AlertComponent.vue";
import spiner from "../components/spiner.vue";
export default {
  name: "App",
  components: {
    AlertComponent,
    spiner,
  },
  data() {
    return {
      currentImage: 0,
      addform: {
        restaurant_id: "",
        user_id:"",
        name: "",
        contact: "",
        description: "",
        place: "1",
        date: "",
      },
      restaurants: [],
      isLoading: false,
      user: "",
      filter: "",
      filters: "",
      passwordErrorMessage: "",
      userID:"",

      showAlert: false,
      alert: {
        type: "",
        message: "",
      },
    };
  },
  computed: {
    filteredRestaurantvilles() {
      const searchTerm = this.filters.toLowerCase();
      const filtered_data = this.restaurants.filter((restaurants) => {
      const ville = restaurants.ville.toLowerCase();
       return ville.includes(searchTerm);
      });
      return filtered_data;
    },
    filteredRestaurants() {
      const searchTerm = this.filter.toLowerCase();
      const filtered_data = this.filteredRestaurantvilles.filter((restaurants) => {
        const name = restaurants.name.toLowerCase();
        //const adresse = restaurants.adresse.toLowerCase();
        return name.includes(searchTerm);
      });
      return filtered_data;
    },
    filteredRestaurant() {
      if (this.addform.restaurant_id !== "") {
        const searchTerm = this.addform.restaurant_id.toLowerCase();

        // Utilisation de find pour obtenir un seul élément avec l'ID spécifié
        const filteredRestaurant = this.restaurants.find((restaurant) => {
          const id = restaurant.id.toLowerCase();
          return id.includes(searchTerm);
        });

        return filteredRestaurant ? [filteredRestaurant] : [];
      } else {
        return [];
      }
    },
  },
  mounted() {
    
     this.profile();
    this.restaurant();
  },
  methods: {
    validatePassword() {
      const passwordPattern = /^[\d*]+$/;

      if (!passwordPattern.test(this.addform.contact)) {
        this.passwordErrorMessage =
          "Le numéro doit contenir uniquement des chiffres.";
      } else {
        this.passwordErrorMessage = "";
      }
    },

    search() {
      if (this.addform.restaurant_id !== "") {
        this.filter = "";
        this.filters = "";
      }
    },
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.userID = response.data.id;
          console.log(this.userID);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async restaurant() {
      try {
        const response = await axios.get("/api/restaurants");
        if (response.data) {
          this.restaurants = response.data.data;
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    reset() {
      this.addform.name = null;
      this.addform.date = null;
      this.addform.contact = null;
      this.addform.place = null;
      this.addform.description = null;
      this.addform.restaurant_id = "";
      window.scrollTo(0, 0);
    },
    async reservation() {
      try {
        this.isLoading = true;
    this.addform.user_id = this.userID;

    // Vérifier si user_id est vide
    if (!this.addform.user_id) {
      this.isLoading = false;
      this.$router.push("/auth/login");
      return;
    }
        const response = await axios.post("/api/reservations", this.addform);
        if (response.status === 201) {
          //this.addform = {}; // Réinitialiser le formulaire après une réservation réussie
          this.isLoading = false;
          this.alert.type = "success";
          this.alert.message = "Votre réservation a été effectuée avec succès";
          setTimeout(() => {
            window.scrollTo(0, 0);
            this.$router.push("/");
            // Effacer le message après 5 secondes
          }, 5000);
          //
        } else {
          this.showModalRepas = !this.showModalRepas;
          this.isLoading = false;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose s'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response && error.response.status !== 500) {
          this.isLoading = false;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose s'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        } else {
          // Gérer l'erreur 500 ici si nécessaire
        }
      }
    },
  },
};
</script>

<style scoped>
.custom-background {
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}

/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
