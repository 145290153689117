<script>
import axios from "axios";
import TokenService from "../services/storage.service";
import ApiService from "../services/api.service";
import TheModal from "../components/TheModal.vue";
import BaseInput from "../components/BaseInput.vue";
import BaseLabel from "../components/BaseLabel.vue";
import AlertComponent from "../components/AlertComponent.vue";
import spiner from "../components/spiner.vue";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
export default {
  name: "LoginForm",
  components: {
    AlertComponent,
    spiner,
     BaseLabel,
    BaseInput,
    TheModal,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        // local: null,
      },
       addform: {
        email: "",
      },
      showAlert: false,
      alert: {
        message: "",
      },
      processing: false,
      isLoading: false,
      user: "",
      role_id: "",
      roles_id: "",
      showModalMotdePasse: false,
    };
  },
  created() {
    this.role();
  },
  methods: {
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        this.user = response.data.role_id;
        if (this.user === this.role_id && this.user !== this.roles_id) {
            this.$router.push("/restaurantdash");
          } else if (this.user === this.roles_id) {
            this.$router.push("/livraisonDash");
          }
          else {
            this.$router.push("/");
          }
       
      } catch (error) {
        console.log(error.data);
      }
    },
    async role() {
      try {
        const response = await axios.get("/api/roles");
        if (response.data) {
          this.role_id = response.data.data[2].id;
          this.roles_id = response.data.data[3].id;
          console.log(this.role_id);
          console.log(this.roles_id);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async login() {
      try {
        this.isLoading = true;
        const response = await axios.post("/api/auth/login", this.form);
        if (response.data.access_token) {
          TokenService.saveToken(response.data.access_token);
          ApiService.setHeader();
          this.isLoading = false;
          this.profile();
          //this.role();
        } else {
          // Gestion d'une réponse sans jeton d'accès
          this.showModalRepas = !this.showModalRepas;
          this.showAlert = true;
          this.isLoading = false;
          this.alert.message =
            "Erreur lors de la connexion. Veuillez réessayer plus tard.";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        // Gestion des erreurs HTTP
        if (error.response && error.response.status === 422) {
          this.showAlert = true;
          this.alert.message = "Adresse e-mail ou mot de passe incorrect.";
        } else {
          this.showAlert = true;
          this.isLoading = false;
          this.alert.message =
            "Quelque chose s'est mal passé. Merci d'essayer plus tard.";
        }

        setTimeout(() => {
          this.showAlert = false;
        }, 5000);
      }
    },
     async motdepasse() {
      try {
        this.isLoading = true;
        const response = await axios.post(
          "/api/auth/password/email",
          this.addform
        );
        if (response.status === 200) {
          this.isLoading = false;
          this.showModalMotdePasse = false;
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Votre demande a été reçue, veuillez vérifier votre boîte mail.",
            timeout: 5000,
          }).show();
        }
      } catch (error) {
        this.isLoading = false;
        // Gestion des erreurs HTTP
        if (error.response && error.response.status === 422) {
          this.showAlert = true;
          this.alert.message = "Adresse e-mail ou mot de passe incorrect.";
        } else {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose s'est mal passé. Merci d'essayer plus tard.";
        }

        setTimeout(() => {
          this.showAlert = false;
        }, 5000);
      }
    },
  },
};
</script>

<template class="">
  <div
    class="p-6 space-y-4 md:space-y-6 sm:p-8 lg:w-1/3 text-white mx-auto my-auto mt-4 mb-4 box-shadow-all-sides"
  >
    <div v-show="showAlert">
      <AlertComponent :content="alert.message" type-alert="error" />
    </div>
    <h1 class="font-bold text-xl text-white">Se connecter</h1>
    <form
      method="POST"
      action="#"
      class="space-y-4 md:space-y-6"
      @submit.prevent="login()"
    >
      <div class="">
        <label class="block font-bold text-sm text-white text-left"
          >Email</label
        >
        <div class="mt-1">
          <input
            class="block w-full p-2 border border-input-disable rounded-md text-gray-800 focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
            type="email"
            v-model="form.email"
            autocomplete="current-email"
            required
          />
        </div>
      </div>
      <div class="space-y-1 mt-5">
        <label class="block font-bold text-sm text-white text-left"
          >Password</label
        >
        <div class="mt-1">
          <input
            class="block w-full p-2 border border-input-disable rounded-md focus:outline-none text-gray-800 focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
            type="password"
            v-model="form.password"
            autocomplete="current-password"
            required
          />
        </div>
      </div>
      <div class="flex items-center justify-between">
        <div class="hidden sm:flex sm:items-start">
          <div class="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              class="rounded border-gray-300 mt-1 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
          <div class="ml-1 text-sm">
            <label
              for="remember"
              class="text-sm font-medium text-primary-normal dark:text-gray-300"
              >Se souvenir de moi
            </label>
          </div>
        </div>

        <a
            @click="showModalMotdePasse = true"
          class="text-sm font-medium text-primary-normal hover:underline dark:text-primary-500"
        >
          Mot de passe oublie
        </a>
      </div>

      <Button
        type="submit"
        class="bg-green-600 text-white px-8 py-2 focus:outline-none poppins rounded-full mt-24 transform transition duration-300 hover:scale-105"
      >
        <span v-if="isLoading">
          <spiner />
        </span>
        <span v-else>Connexion</span>
      </Button>
    </form>
    <p class="text-sm text-white">
      Pas encore de compte
      <router-link
        to="/auth/register"
        class="font-sans text-blue-700 hover:underline dark:text-primary-500"
      >
        Inscrivez vous ici</router-link
      >
    </p>
  </div>
      <TheModal
    width="w-full md:w-2/3 lg:w-1/3"
    :is-open="showModalMotdePasse"
    @close-modal="showModalMotdePasse = false"
  >
    <template #header>Mot de passe oublier</template>

    <template #body>
      <div v-show="showAlert">
        <AlertComponent :content="alert.message" type-alert="error" />
      </div>
      <form action="#" method="POST" @submit.prevent="motdepasse()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Mail" />
                  <div class="relative mt-1">
                    <BaseInput
                      v-model="addform.email"
                      class="mt-2 text-black"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <Button
        type="submit"
        class="bg-green-600 text-gray-700 px-4 mt-2 mb-2 py-2 focus:outline-none poppins rounded-lg transform transition duration-300 hover:scale-105"
        @click="motdepasse()"
      >
        <span v-if="isLoading">
          <spiner />
        </span>
        <span v-else> Envoyer</span>
      </Button>
    </template>
  </TheModal>
</template>
<style scoped>
.box-shadow-all-sides {
  box-shadow: 1px 1px 8px rgba(0.2, 0.2, 0.2, 0.2); /* Ajustez les valeurs en conséquence */
}
</style>
