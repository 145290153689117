<template>
  <div class="">
    <div class="lg:mt-20 mt-12">
      <img class="w-full" src="../assets/Commande.jpg" alt="Image 2" />
    </div>
    <div v-show="showAlert">
      <AlertComponent :content="alert.message" type-alert="error" />
    </div>

    <!-- component -->
    <div class="flex mt-6">
      <div class="flex-1 border-r-2 pr-4">
        <!-- Contenu à gauche -->
        <h1 class="text-4xl font-bold border border-red-200 mt-4"></h1>
      </div>
      <div class="flex-1 text-center">
        <!-- Contenu au milieu -->
        <h1 class="lg:text-4xl text-2xl font-bold bg-green-500 rounded-lg">
          Fiche de commande
        </h1>
      </div>
      <div class="flex-1 border-l-2 pl-4">
        <!-- Contenu à droite -->
        <h1 class="text-4xl font-bold border border-red-200 mt-4"></h1>
      </div>
    </div>
    <!-- <label for="restaurant" class="mb-3 block text-2xl font-bold mt-12">
      Recherchez un repas
    </label>
    <div
      class="rounded-full p-1 mt-4 box-border border border-orange-500 mx-auto my-auto bg-white overflow-hidden ring-red-300 focus:ring-4 w-2/4 flex items-center py-1"
    >
      <input
        type="text"
        v-model="filter"
        class="rounded-full px-4 focus:outline-none w-full"
        placeholder="Recherche ......."
      />
      <button
        class="text-sm bg-green-600 py-2 px-6 rounded-full text-white poppins ring-red-300 focus:ring-4 transition duration-300 hover:scale-105 transform"
      >
        Search
      </button>
    </div>-->
    <div class="flex items-center justify-center lg:p-12 p-2">
      <!-- Author: FormBold Team -->
      <!-- Learn More: https://formbold.com -->

      <div class="mx-auto w-full max-w-[550px] lg:border border-gray-500">
        <div class="mb-5 lg:mx-24">
          <label
            for="restaurant"
            class="mb-3 block lg:text-2xl text-lg font-bold mt-4"
          >
            Choisissez la catégorie/spécialité
          </label>
          <select
            name="restaurant"
            id="restaurant"
            v-model="filter"
            class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          >
            <option value="">Tous</option>
            <option
              v-for="(categorie, index) in categories"
              :key="index"
              :value="categorie.name"
            >
              {{ categorie.name }}
            </option>
            <!-- Ajoutez plus d'options au besoin -->
          </select>
        </div>
        <div class="mb-5 lg:mx-24">
          <label
            for="restaurant"
            class="mb-3 block lg:text-2xl text-lg font-bold mt-2"
          >
            Choisissez un restaurant
          </label>
          <select
            name="restaurant"
            id="restaurant"
            v-model="addform.restaurant_id"
            @change="searchr"
            :disabled="isSelectDisabled"
            class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          >
            <option
              v-for="(restaurant, index) in availableRestaurants"
              :key="index"
              :value="restaurant.id"
            >
              {{ restaurant.name }}
            </option>
            <!-- Ajoutez plus d'options au besoin -->
          </select>
        </div>

        <div v-if="this.addform.restaurant_id !== ''">
          <div class="mb-5 lg:mx-24">
            <div class="lg:flex">
              <div class="lg:w-1/2">
                <label
                  for="restaurant"
                  class="mb-3 block lg:text-2xl text-lg font-bold mt-4"
                >
                  Choisissez un repas/boisons
                </label>
                <div class="flex flex-col md:flex-row gap-3">
                  <select
                    id="pricingType"
                    name="pricingType"
                    v-model="form.repas_id"
                    class="w-full md:w-80 rounded-md border border-gray-400 bg-white py-3 px-6 focus:outline-none focus:border-sky-500"
                  >
                    <option
                      v-for="(repas, index) in filteredRestaurantMenu"
                      :key="index"
                      :value="repas.id"
                    >
                      {{ repas.name }}
                    </option>
                  </select>
                  <div class="flex">
                    <input
                      type="text"
                      v-model="filter"
                      placeholder="Rechercher un repas"
                      class="w-full rounded-l-lg border border-gray-400 bg-white py-3 px-6"
                    />
                    <button
                      type="submit"
                      class="bg-green-600 text-white rounded-r px-2 md:px-3 py-0 md:py-1"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div class="lg:w-1/2 flex ">
                <div class="ml-2">
                  <label
                    for="guest"
                    class="mb-3 block lg:text-2xl text-lg font-bold mt-4"
                  >
                    Prix
                  </label>
                  <input
                    type="text"
                    name="guest"
                    id="guest"
                    v-model="menuSelectionne"
                    class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
                <div class="ml-2">
                  <label
                    for="guest"
                    class="mb-3 block lg:text-2xl text-lg font-bold mt-4"
                  >
                    Quantité
                  </label>
                  <input
                    type="number"
                    name="guest"
                    id="guest"
                    v-model="form.quantite"
                    placeholder="1"
                    min="0"
                    class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
                <div class="col-span-8 sm:col-span-4 mt-16 ml-4">
                  <button
                    @click="ligneCommande"
                    class="inline-flex text-white bg-green-700 mt-1 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="lg:mx-24 flex flex-wrap" v-if="allcommande.length > 0">
          <div
            v-for="(cont, index) in allcommande"
            :key="index"
            class="lg:w-1/4"
          >
            <div class="mt-2 p-2 border border-dark-500 rounded mr-2">
              {{ cont.quantite }} {{ cont.repas.name }} {{ cont.montant }} FCFA
              <button @click="remove(cont.id, cont.montant)">
                <span class="sr-only">Close</span>
                <svg
                  class="w-5 h-5 ml-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <span
            class="mt-2 text-blue-400 font-bold text-xl p-2 border border-dark-500 rounded"
          >
            Total: {{ this.TotalMontant }} FCFA</span
          >
        </div>
        <div v-else></div>

        <form
          action="#"
          method="POST"
          @submit.prevent="!passwordErrorMessage && commandeUpdate()"
        >
          <div class="flex flex-wrap lg:mx-24">
            <div class="w-full sm:w-1/2">
              <div class="mb-5">
                <label
                  for="fName"
                  class="mb-3 block lg:text-2xl text-lg font-bold"
                >
                  Nom Complet
                </label>
                <input
                  type="text"
                  name="fName"
                  id="fName"
                  v-model="addform.name"
                  placeholder=" Nom Complet"
                  class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
              </div>
            </div>
            <div class="w-full sm:w-1/2">
              <div class="mb-5 ml-2">
                <label
                  for="lName"
                  class="mb-3 block lg:text-2xl text-lg font-bold"
                >
                  Contact
                </label>
                <input
                  type="text"
                  name="lName"
                  id="lName"
                  v-model="addform.contact"
                  @input="validatePassword"
                  placeholder="Contact"
                  class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
              </div>
              <div class="text-red-500" v-if="passwordErrorMessage">
                {{ passwordErrorMessage }}
              </div>
            </div>
          </div>
          <div class="lg:mx-24 flex flex-wrap">
            <div class="w-full sm:w-1/2">
              <div class="mb-5">
                <label
                  for="fName"
                  class="mb-3 block lg:text-2xl text-lg font-bold"
                >
                  Adresse de livraison
                </label>
                <input
                  type="text"
                  name="fName"
                  id="fName"
                  v-model="addform.adresse"
                  placeholder=" Adresse"
                  class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
              </div>
            </div>
            <div class="w-full sm:w-1/2">
              <div class="mb-5 ml-2">
                <label
                  for="lName"
                  class="mb-3 block lg:text-2xl text-lg font-bold"
                >
                  Informations Complémentaires
                </label>
                <input
                  type="text"
                  name="lName"
                  id="lName"
                  v-model="addform.description"
                  placeholder="Decription"
                  class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
              </div>
            </div>
          </div>
          <div class="flex justify-center">
            <button
              class="hover:shadow-form mr-2 rounded-md border border-gray-200 py-3 px-8 text-center text-base font-semibold text-black outline-none"
              type="button"
              @click="reset()"
            >
              Annuler
            </button>
            <button
              class="hover:shadow-form ml-2 rounded-md bg-green-400 py-3 px-8 text-center text-base font-semibold text-white outline-none"
            >
              <span v-if="isLoading">
                <spiner />
              </span>
              <span v-else>Valider</span>
            </button>
          </div>

          <div class="h-4"></div>
        </form>
      </div>
    </div>
  </div>
  <TheModal
    width="w-full md:w-2/3 lg:w-1/4"
    :is-open="showModalCommande"
    @close-modal="showModalCommande = false"
  >
    <template #header> Numero de payement</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="LivreCommande()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div
                  v-if="numResto.celtispay !== null"
                  class="col-span-8 sm:col-span-8 flex items-center"
                >
                  <BaseLabel value="Numéro CELTISE" />
                  <label
                    class="block font-medium text-sm text-gray-700 mt-2 bg-gray-200 px-4 rounded-full"
                    >{{ numResto.celtispay }}</label
                  >
                  <svg
                    @click="copyToClipboard(numResto.celtispay)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-green-400 ml-4 mt-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
                    />
                  </svg>
                </div>
                <div
                  v-if="numResto.moovpay !== null"
                  class="col-span-8 sm:col-span-8 flex items-center"
                >
                  <BaseLabel value="Numéro MOOV" />
                  <label
                    class="block font-medium text-sm text-gray-700 mt-2 bg-gray-200 px-4 rounded-full"
                    >{{ numResto.moovpay }}</label
                  >
                  <svg
                    @click="copyToClipboard(numResto.moovpay)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-green-400 ml-4 mt-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
                    />
                  </svg>
                </div>
                <div
                  v-if="numResto.mtnpay !== null"
                  class="col-span-8 sm:col-span-8 flex items-center"
                >
                  <BaseLabel value="Numéro MTN" />
                  <label
                    class="block font-medium text-sm text-gray-700 mt-2 bg-gray-200 px-4 rounded-full"
                    >{{ numResto.mtnpay }}</label
                  >

                  <svg
                    @click="copyToClipboard(numResto.mtnpay)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-green-400 ml-4 mt-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
  </TheModal>
</template>

<script>
import axios from "axios";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from "kkiapay";
import TheModal from "../components/TheModal.vue";
import BaseLabel from "../components/BaseLabel.vue";
import AlertComponent from "../components/AlertComponent.vue";
//import AddModalFooter from "../components/AddModalFooter.vue";
import spiner from "../components/spiner.vue";
//import BaseInput from "../components/BaseInput.vue";

export default {
  name: "App",
  components: {
    AlertComponent,
    spiner,
    TheModal,
    BaseLabel,
    //AddModalFooter,
    //BaseInput
  },
  data() {
    return {
      addform: {
        restaurant_id: "",
        name: "",
        contact: "",
        status: "En attente",
        description: "",
        adresse: "",
        user_id: "",
        montant: 0,
      },

      form: {
        repas_id: "",
        quantite: 1,
        montant: 0,
        commande_id: "",
      },
      restaurants: [],
      repass: [],
      repasMenu: [],
      user: "",
      filter: "",
      passwordErrorMessage: "",
      showModalCommande: false,
      //filters:"",
      isLoading: false,
      filteredRespas: [],
      RepaFitre: [],
      filteredMenus: [],
      filteredMenuRestau: [],
      commandeligne: [],
      allcommande: [],
      categories: [],
      showAlert: false,
      isSelectDisabled: false,
      commandeID: "",
      ligneMontant: 0,
      TotalMontant: 0,
      transationID: "",
      deleteID: "",
      prixSelectionne: null,
      menuSelectionne: null,
      prixmenu: null,
      uniquePrix: 0,
      //filteredPrix:[],
      numResto: "",
      alert: {
        message: "",
      },
    };
  },
  computed: {
     availableRestaurants() {
      // This computed property filters restaurants based on their status
      return this.restaurants.filter(restaurant => restaurant.status === 'disponible');
    },
  
    filteredRepas() {
      const searchTerm = this.filter.toLowerCase();
      const filtered_data = this.repass.filter((repass) => {
        const name = repass.name.toLowerCase();
        const categorie = repass.categoris.name.toLowerCase();
        return name.includes(searchTerm) || categorie.includes(searchTerm);
      });

      return filtered_data;
    },
    filteredRestaurants() {
      const searchTerm = this.filter.toLowerCase();
      const filtered_data = this.restaurants.filter((restaurants) => {
        const name = restaurants.name.toLowerCase();
        const adresse = restaurants.adresse.toLowerCase();
        return name.includes(searchTerm) || adresse.includes(searchTerm);
      });
      return filtered_data;
    },
    filteredRestaurantMenu() {
      const searchTerm = this.filter.toLowerCase();
      const filtered_data = this.filteredMenus.filter((menu) => {
        const name = menu.name.toLowerCase();
        //const adresse = restaurants.adresse.toLowerCase();
        return name.includes(searchTerm);
      });
      return filtered_data;
    },
  },
  mounted() {
    this.profile();
    this.restaurant();
    this.getRepas();
    this.getCategories();
    addKkiapayListener("success", this.successHandler);
    //this.getMenus();
  },
  beforeUnmount() {
    removeKkiapayListener("success", this.successHandler);
  },

  watch: {
    "form.repas_id": function (newValue) {
      if (newValue !== "") {
        this.AddPrix();
      }
    },
  },
  methods: {
    validatePassword() {
      const passwordPattern = /^[\d*]+$/;

      if (!passwordPattern.test(this.addform.contact)) {
        this.passwordErrorMessage =
          "Le numéro doit contenir uniquement des chiffres.";
      } else {
        this.passwordErrorMessage = "";
      }
    },
    search(id) {
      this.form.repas_id = id;
      this.getMenuRest();
      if (this.form.repas_id !== "") {
        this.filter = "";
      }
    },
    updatePrix() {
      // Récupérer le repas sélectionné
      const repasSelectionne = this.repass.find(
        (repas) => repas.id === this.form.repas_id
      );

      // Vérifier si le repas a été trouvé
      if (repasSelectionne) {
        // Mettre à jour la variable avec le prix du repas
        this.prixSelectionne = repasSelectionne.prix.split(".")[0];
        //console.log(this.prixSelectionne);
      } else {
        // Gérer le cas où le repas n'est pas trouvé
        this.prixSelectionne = null; // ou une valeur par défaut
      }
    },
    AddPrix() {
      // Récupérer le repas sélectionné
      const repasSelectionne = this.filteredRestaurantMenu.find(
        (repas) => repas.id === this.form.repas_id
      );

      // Vérifier si le repas a été trouvé
      if (repasSelectionne) {
        // Mettre à jour la valeur du prix
        this.menuSelectionne = repasSelectionne.prix.split(".")[0];
      } else {
        // Gérer le cas où le repas n'est pas trouvé
        this.menuSelectionne = null; // ou une autre valeur par défaut
      }
    },
    searchr() {
      this.getMenus();
      if (this.addform.restaurant_id !== "") {
        this.filter = "";
      }
    },
    async getMenuRest() {
      try {
        const response = await axios.get("/api/menus");
        if (response.data) {
          this.repasMenu = response.data.data;
          this.filteredMenuRestau = this.repasMenu.filter(
            (menu) => menu.repas.id === this.addform.repas_id
          );
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.user = response.data.id;
          this.filterRestaurantsByUser(this.user);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async getRepas() {
      try {
        const response = await axios.get("/api/repas");
        if (response.data) {
          this.repass = response.data.data;
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    reset() {
      this.addform = {};
      this.form = {};
      this.prixSelectionne = null;
      this.menuSelectionne = null;
      this.allcommande = [];
      window.scrollTo(0, 0);
    },
    async getCategories() {
      try {
        const response = await axios.get("/api/categorys");
        if (response.data) {
          this.categories = response.data.data;
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async restaurant() {
      try {
        const response = await axios.get("/api/restaurants");
        if (response.data) {
          this.restaurants = response.data.data;
          console.log(this.restaurants);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    RepasModal() {
      this.RepaFitre = this.repass.filter(
        (repas) => repas.id === this.form.repas_id
      );
    },
    async ligneCommande() {
      if (this.commandeID === "") {
        this.commande();
      } else {
        try {
          this.isLoading = true;
          this.RepasModal();
          this.form.montant = this.RepaFitre[0].prix * this.form.quantite;
          this.form.commande_id = this.commandeID;
          const response = await axios.post("/api/lignecommandes", this.form);
          if (response.status == 201) {
            this.form = {};
            this.prixSelectionne = null;
            this.menuSelectionne = null;
            this.commandeligne = response.data.data;
            this.ligneMontant = response.data.data.montant;
            this.TotalMontant = this.TotalMontant + this.ligneMontant;
            console.log(this.TotalMontant);
            this.allcommande.push(this.commandeligne);
            console.log(this.allcommande);
            this.form.quantite = 1;
            this.isSelectDisabled = true;
            this.isLoading = false;
          }
        } catch (error) {
          if (error.response.status !== 500) {
            this.showAlert = true;
            this.alert.message =
              "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
            setTimeout(() => {
              this.showAlert = false;
            }, 5000);
          }
        }
      }
    },
    async commande() {
      try {
        this.isLoading = true;
        this.addform.user_id = this.user;

        // Vérifier si user_id est vide
        if (!this.addform.user_id) {
          this.isLoading = false;
          this.$router.push("/auth/login");
          return;
        }
        this.RepasModal();

        const response = await axios.post("/api/commandes", this.addform);
        if (response.status == 201) {
          this.commandeID = response.data.data.id;
          console.log(this.commandeID);
          this.isLoading = false;
          this.ligneCommande();
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async commandeUpdate() {
      try {
        this.isLoading = true;
        this.RepasModal();
        const data = {
          name: this.addform.name,
          description: this.addform.description,
          contact: this.addform.contact,
          adresse: this.addform.adresse,
          montant: this.TotalMontant,
          status: this.addform.status,
        };
        const response = await axios.post(
          `/api/commandes/${this.commandeID}`,
          data
        );
        if (response.status == 200) {
          this.isLoading = false;
          console.log(response);
          (this.numResto = response.data.data.restaurant),
            console.log(this.numResto);
          this.showModalCommande = true;
          //this.createTransaction();
          this.reset();
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.isLoading = false;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async remove(id, montantTotal) {
      try {
        this.deleteID = id;
        this.uniquePrix = montantTotal;
        console.log(this.uniquePrix);
        const response = await axios.delete(
          `/api/lignecommandes/${this.deleteID}`
        );
        if (response.status == 204) {
          this.allcommande = this.allcommande.filter(
            (commande) => commande.id !== id
          );
          this.TotalMontant = this.TotalMontant - this.uniquePrix;
          console.log(this.allcommande);
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Votre commande a été supprimer avec succès.",
            timeout: 5000,
          }).show();
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async getMenus() {
      try {
        const response = await axios.get(
          `/api/repa?restaurant_id=${this.addform.restaurant_id}`
        );
        if (response.data) {
          console.log(response.data.data);
          this.filteredMenus = response.data.data;
          console.log(this.filteredMenus);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async payementmethod() {
      try {
        const data = {
          transationId: this.transationID,
          commande_id: this.commandeID,
        };

        const response = await axios.post("/api/payments", data);
        if (response.status == 201) {
          console.log(response);
          (this.allcommande = []), (this.isSelectDisabled = false);
          window.scrollTo(0, 0);
          this.$router.push("/");
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Votre commande a été enregistrée avec succès.",
            timeout: 5000,
          }).show();
        } else {
          this.showModalRepas = !this.showModalRepas;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async createTransaction() {
      //this.addform.montant = this.filteredRepas[0].prix * this.addform.quantite;
      openKkiapayWidget({
        amount: this.TotalMontant,
        firstname: this.addform.name,
        lastname: this.addform.name,
        api_key: "361197d0e64411ec848227abfc492dc7",
        sandbox: true,
        phone: "61000000",
      });
    },
    successHandler(response) {
      this.transationID = response.transactionId;
      //this.this.form = {},
      //this.this.addform = {},
      this.payementmethod();
    },
  },
};
</script>

<style scoped>
.custom-background {
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /*background-size: auto 100%;*/
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}

/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
