<template>
  <div class="">
    <div v-show="showAlert">
      <AlertComponent :content="alert.message" type-alert="error" />
    </div>
    <div
      class="custom-background flex flex-col items-center justify-start font-sans min-h-96 lg:pt-10 lg:pb-20 lg:mt-20"
    >
      <div class="mt-16 lg:mt-0">
        <p class="p-2 text-2xl font-bold text-center lg:text-5xl text-gray-100">
          Pourquoi rester affamé quand vous pouvez commander
        </p>
        <p class="p-2 text-2xl font-bold text-center lg:text-5xl text-gray-100">
          chez WADOUNNOU ?
        </p>
      </div>
      <div>
        <p
          class="p-4 pt-6 font-sans text-2xl leading-10 text-center text-gray-200"
        >
          Téléchargez dès maintenant l'application WADOUNNOU
        </p>
      </div>
      <div
        class="relative z-50 flex flex-col items-center justify-between h-48 lg:space-x-8 pt-7 lg:pt-0 lg:flex-row lg:justify-between lg:w-90"
      >
        <a
          href="https://bucetwadounou.s3.amazonaws.com/medias/app-release.apk"
          class="pt-3 pb-3 pl-12 pr-12 text-2xl font-semibold text-center text-white transition-all bg-orange-600 rounded-full shadow-2xl lg:ml-5 hover:bg-orange-700 focus:outline-none ring-4 ring-orange-600 lg:ring-2 lg:font-medium"
        >
          Téléchargez l’apk
        </a>
        <a
          href="#"
          class="pt-3 pb-3 text-2xl hidden md:block font-semibold text-center text-orange-500 transition-all rounded-full shadow-2xl lg:mr-5 hover:text-orange-500 hover:bg-gray-50 pl-11 pr-11 bg-gray-50 focus:outline-none ring-4 ring-orange-500 lg:font-medium lg:text-gray-50 lg:bg-opacity-0 lg:ring-2 lg:ring-white"
        >
          App Store
        </a>
      </div>
    </div>
    <div class="flex">
      <div class="lg:w-1/3 lg:mt-4 hidden md:block">
        <transition name="fade" mode="out-in">
          <div :key="currentImage">
            <img
              :src="images[currentImage].src"
              :alt="'Image ' + (currentImage + 1)"
              class="h-72 object-cover ml-24"
            />
            <p class="text-center text-xl font-sans font-bold mt-2">
              {{ images[currentImage].name }}
            </p>
          </div>
        </transition>
      </div>
      <div
        class="z-0 flex flex-row hidden md:block items-start justify-center w-screen h-screen pt-20 -mb-16 bg-gray-50 lg:bg-white lg:w-1/3 lg:h-96 lg:pt-0"
      >
        <img
          class="absolute left-100 lg:-mt-32 h-96"
          src="../assets/phone1.png"
          alt=""
        />
        <img class="ml-52 lg:-mt-24 h-96" src="../assets/phone2.png" alt="" />
      </div>
      <div class="lg:w-1/3 mt-4 lg:ml-4 ml-9">
        <transition name="fade" mode="out-in">
          <div :key="currentImage">
            <img
              :src="image[currentImage].src"
              :alt="'Image ' + (currentImage + 1)"
              class="h-72 lg:ml-16 h-72 object-cover"
            />
            <p class="text-center text-xl font-sans font-bold mt-2 mr-9">
              {{ image[currentImage].name }}
            </p>
          </div>
        </transition>
      </div>
    </div>

    
    <label for="restaurant" class="mb-3 block lg:text-2xl text-xl font-bold mt-9">
      Recherchez un restaurant
    </label>
    <div
      class="w-3/4 mx-9  rounded-full p-1 mt-2 box-border border border-orange-500 lg:mx-auto lg:my-auto bg-white overflow-hidden ring-red-300 focus:ring-4 w-2/4 flex items-center py-1"
    >
      <input
        type="text"
        v-model="filters"
        class="rounded-full px-4 focus:outline-none lg:w-full"
        placeholder="Recherche ......."
      />
      <button
        class="text-sm bg-green-600 hidden md:block py-2 px-6 rounded-full text-white poppins ring-red-300 focus:ring-4 transition duration-300 hover:scale-105 transform"
      >
        Search
      </button>
    </div>
    <div class="flex mt-6 flex-wrap">
      <div
        v-for="(repas, index) in restaurants"
        :key="index"
        class="lg:w-1/4 p-4"
      >
      <router-link :to="`/restaurantdetail/${repas.id}`">
        <div class="bg-white relative">
          <img
            class="h-52 w-96 rounded-lg mx-auto transform transition duration-300 hover:scale-105"
            :src="repas.image_url"
            alt=""
          />
          <!-- Bouton vert "Disponible" -->
          <button
          class="absolute bottom-2 left-0 text-white py-1 px-2 rounded-bl-lg"
          :class="statusClass(repas.status)"
        >
          {{ repas.status }}
        </button>
          <button
            class="absolute bottom-36 lg:left-72 left-64 text-white bg-yellow-300 py-1 px-4 rounded-bl-lg"
          >
           {{ repas.moy_note ? repas.moy_note.toFixed(1) : '0' }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 text-green-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
              />
            </svg>
          </button>
          </div>
          <!-- Contenu supplémentaire -->
          <div class="flex flex-col my-3 space-y-2">
         
            <h1 class="text-gray-900  text-lg">{{ repas.name }} </h1>
           
           
            <p class="text-gray-500 poppins text-sm flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span class="mt-0 ml-4"
                >{{ repas.ville }} {{ repas.quatier }} {{ repas.adresse }}</span
              >
            </p>
          </div>
        
      </router-link>
      </div>
    </div>
    <div class="flex justify-center mt-4">
      <nav aria-label="Page navigation example">
        <ul class="flex items-center -space-x-px h-8 text-sm">
          <li>
            <a
              @click.prevent="goToPage(currentPage - 1)"
              :class="{ 'opacity-50 cursor-not-allowed': currentPage === 1 }"
              class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span class="sr-only">Previous</span>
              <svg
                class="w-2.5 h-2.5 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
            </a>
          </li>
          <li v-for="page in pages" :key="page">
            <a
              @click.prevent="goToPage(page)"
              :class="{
                'z-10 flex items-center justify-center px-3 h-8 leading-tight': true,
                'text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white':
                  currentPage === page,
                'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white':
                  currentPage !== page,
              }"
            >
              {{ page }}
            </a>
          </li>

          <li>
            <a
              @click.prevent="goToPage(currentPage + 1)"
              :class="{
                'opacity-50 cursor-not-allowed': currentPage === totalPages,
              }"
              class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span class="sr-only">Next</span>
              <svg
                class="w-2.5 h-2.5 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </div>

    <section class="pt-4 pb-12 bg-gray-800 mt-6">
      <div class="my-16 text-center">
        <h2
          class="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500"
        >
          Quelques temoignages
        </h2>
        <p class="text-xl text-white">
          L'entreprise elle-même est une entreprise très prospère. lâche sage
        </p>
      </div>
      <!--<div class="grid gap-2 lg:grid-cols-3 mx-4">
        <div class="max-w-md p-4 bg-white rounded-lg shadow-lg">
          <div class="flex justify-center -mt-16 md:justify-end">
            <img
              class="object-cover w-20 h-20 border-2 border-green-500 rounded-full"
              src="https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
            />
          </div>
          <div>
            <h2 class="text-3xl font-semibold text-gray-800">
              Groupe Tournier
            </h2>
            <p class="mt-2 text-gray-600">
              Nous apprécions tout particulièrement la facilité d'utilisation de
              l'application WADOUNOU tant au niveau du paramétrage que dans leur
              utilisation quotidienne par nos équipes permettant une prise en
              main rapide et efficace.
            </p>
          </div>
          <div class="flex justify-end mt-4">
            <a href="#" class="text-xl font-medium text-green-500">Yassine</a>
          </div>
        </div>
        <div class="max-w-md p-4 bg-white rounded-lg shadow-lg mt-12 lg:mt-0">
          <div class="flex justify-center -mt-16 md:justify-end">
            <img
              class="object-cover w-20 h-20 border-2 border-green-500 rounded-full"
              src="https://cdn.pixabay.com/photo/2018/01/04/21/15/young-3061652__340.jpg"
            />
          </div>
          <div>
            <h2 class="text-3xl font-semibold text-gray-800">Services</h2>
            <p class="mt-2 text-gray-600">
              L'interface utilisateur est incroyablement conviviale, ce qui
              facilite la navigation et la recherche de mes plats préférés. Le
              choix de restaurants est vaste, offrant une variété de cuisines
              pour satisfaire tous les goûts. Je suis constamment impressionné
              par la rapidité avec laquelle je peux passer ma commande
            </p>
          </div>
          <div class="flex justify-end mt-4">
            <a href="#" class="text-xl font-medium text-green-500"
              >Uriel FONTON</a
            >
          </div>
        </div>
        <div class="max-w-md p-4 bg-white rounded-lg shadow-lg mt-12 lg:mt-0">
          <div class="flex justify-center -mt-16 md:justify-end">
            <img
              class="object-cover w-20 h-20 border-2 border-green-500 rounded-full"
              src="https://cdn.pixabay.com/photo/2018/01/18/17/48/purchase-3090818__340.jpg"
            />
          </div>
          <div>
            <h2 class="text-3xl font-semibold text-gray-800">Application</h2>
            <p class="mt-2 text-gray-600">
              J'utilise WADOUNOU depuis quelques mois maintenant, et cela a
              vraiment transformé la façon dont je commande de la nourriture.
              C'est de loin la meilleure application de livraison que j'ai
              essayée.
            </p>
          </div>
          <div class="flex justify-end mt-4">
            <a href="#" class="text-xl font-medium text-green-500"
              >John GAGNON</a
            >
          </div>
        </div>
      </div>-->
    </section>
  </div>
  <!-- Composant de modal -->
  <ModalComponent :isOpen="isModalOpen" @close="closeModal" />
  <TheModal
    width="w-3/4 md:w-2/3 lg:w-1/2"
    :is-open="showModalRepas"
    @close-modal="showModalRepas = false"
  >
    <template #header> Faire votre commande</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="commande()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div class="col-span-8 sm:col-span-4">
                  <BaseLabel value="Nom " />
                  <BaseInput id="nom" v-model="addform.name" class="mt-2" />
                </div>
                <div class="col-span-8 sm:col-span-4">
                  <BaseLabel value="Addrese" />
                  <BaseInput
                    id="prenom"
                    v-model="addform.adresse"
                    class="mt-2"
                  />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Télèphone" />
                  <div class="relative mt-1">
                    <BaseInput v-model="addform.contact" class="mt-2" />
                  </div>
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel
                    value="Informations Complémentaires (pas obligatoire)"
                  />
                  <BaseInput
                    id="language"
                    v-model="addform.description"
                    class="mt-2"
                  />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Qunatité" />
                  <BaseInput
                    id="language"
                    v-model="addform.quantite"
                    class="mt-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <AddModalFooter @cancel="showModalRepas = false" @send="commande()" />
    </template>
  </TheModal>
  <TheModal
    width="w-2/3 md:w-2/3 lg:w-1/2"
    :is-open="showModalCommentaires"
    @close-modal="showModalCommentaires = false"
  >
    <template #header> Votre commentaire</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="commentaire()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="lg:px-4 py-5 bg-white lg:p-6 px-2">
              <div class="grid grid-cols-8 gap-6">
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Nom " />
                  <BaseInput id="nom" class="mt-2" />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Votre message " />
                  <div class="mt-1">
                    <textarea
                      class="block lg:w-full p-2 border border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
                      v-model="sendform.content"
                      autocomplete="current-password"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <AddModalFooter
        @cancel="showModalCommentaires = false"
        @send="commentaire()"
      />
    </template>
  </TheModal>
</template>

<script>
import axios from "axios";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from "kkiapay";
import ModalComponent from "../components/ModalComponent .vue";
import TheModal from "../components/TheModal.vue";
import BaseLabel from "../components/BaseLabel.vue";
import BaseInput from "../components/BaseInput.vue";
import AddModalFooter from "../components/AddModalFooter.vue";
import AlertComponent from "../components/AlertComponent.vue";
//import spiner from "../components/spiner.vue";

export default {
  name: "App",
  components: {
    ModalComponent,
    TheModal,
    BaseLabel,
    BaseInput,
    AlertComponent,
    AddModalFooter,
    //spiner
  },
  data() {
    return {
      images: [
        { src: require("@/assets/breakfast2.png"), name: "Petit-déjeuner 1" },
        {
          src: require("@/assets/breakfast4.png"),
          name: "Petit-déjeuner Bénin",
        },
        { src: require("@/assets/breakfast6.png"), name: "Petit-déjeuner " },
        { src: require("@/assets/lunch3.png"), name: "Déjeuner" },
      ],

      image: [
        { src: require("@/assets/lunch4.png"), name: "Plat Africa" },
        { src: require("@/assets/lunch5.png"), name: "Plat Bénin" },
        { src: require("@/assets/lunch6.png"), name: "Déjeuner Sud" },
        { src: require("@/assets/dinner2.png"), name: "Déjeuner Nord" },
      ],

      currentImage: 0,
      currentIndex: 0,
      showAlert: false,
      alert: {
        message: "",
      },
      isModalOpen: false,
      addform: {
        repas_id: "",
        name: "",
        contact: "",
        description: "",
        adresse: "",
        user_id: "",
        quantite: 1,
        status: "En attente",
        montant: 0,
        commande_id: "",
      },
      sendform: {
        repas_id: "",
        content: "",
      },
      showModalRepas: false,
      showModalCommentaires: false,
      isLoading: false,
      repass: [],
      restaurants: [],
      CommentaireId: "",
      filteredRepas: [],
      pris: 0,
      filters: "",
      filter: "",
      commandeID: "",
      transationID: "",
      currentPage: 1,
      itemsPerPage: 16,
      totalPages: 5,
    };
  },
  computed: {
    filteredRestaurant() {
      const searchTerm = this.filter.toLowerCase();
      const filtered_data = this.restaurants.filter((restaurants) => {
        const name = restaurants.user.id.toLowerCase();
        return name.includes(searchTerm);
      });

      return filtered_data;
    },
    filteredRestau() {
      const searchTerm = this.filter.toLowerCase();
      const filtered_data = this.restaurants.filter((restaurants) => {
        const name = restaurants.name.toLowerCase();
        const adresse = restaurants.adresse.toLowerCase();
        return name.includes(searchTerm) || adresse.includes(searchTerm);
      });

      return filtered_data;
    },
    filteredRepa() {
      const searchTerm = this.filters.toLowerCase();
      const filtered_data = this.repass.filter((repass) => {
        const name = repass.name.toLowerCase();
        return name.includes(searchTerm);
      });

      return filtered_data;
    },
    // Calcule les cartes à afficher en fonction de la page actuelle
    // paginatedCards() {
    //   const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    //   const endIndex = startIndex + this.itemsPerPage;
    //   return this.cards.slice(startIndex, endIndex);
    // },
    paginatedCards() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      // Vérifier si la page actuelle est valide
      if (startIndex >= this.filteredRepa.length || endIndex <= 0) {
        return []; // Retourner un tableau vide si la page est invalide
      }

      return this.filteredRepa.slice(startIndex, endIndex);
    },
    // Vérifie si le bouton "Charger plus" doit être affiché
    showLoadMoreButton() {
      return this.currentPage * this.itemsPerPage < this.filteredRepa.length;
    },
    // pages() {

    //   return Array.from({ length: this.totalPages }, (_, index) => index + 1);
    // },
    pages() {
      const totalCards = this.filteredRepa.length;
      const totalPages = Math.ceil(totalCards / this.itemsPerPage);
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    },
  },
  mounted() {
    this.startSlider();
    this.getRepas();
    this.restaurant();
    addKkiapayListener("success", this.successHandler);
  },
  beforeUnmount() {
    removeKkiapayListener("success", this.successHandler);
  },
  methods: {
    startSlider() {
      setInterval(() => {
        this.currentImage = (this.currentImage + 1) % this.images.length;
      }, 5000); // Défilement toutes les 5 secondes
    },
    openModal() {
      this.isModalOpen = true; // Ouvrir le modal
    },
    closeModal() {
      this.isModalOpen = false; // Fermer le modal
    },
    CommentaireModal(id) {
      this.showModalCommentaires = !this.showModalCommentaires;
      this.sendform.repas_id = id;
    },
    RepasModal(id) {
      this.showModalRepas = !this.showModalRepas;
      this.addform.repas_id = id;
      this.filteredRepas = this.repass.filter(
        (repas) => repas.id === this.addform.repas_id
      );

      this.addform.montant = this.filteredRepas[0].prix;
    },

    async commande() {
      try {
        this.isLoading = true;
        this.filters = "";
        this.addform.montant =
          this.filteredRepas[0].prix * this.addform.quantite;
        const data = {
          name: this.addform.name,
          description: this.addform.description,
          contact: this.addform.contact,
          addrese: this.addform.adresse,
          montant: this.addform.montant,
          status: this.addform.status,
        };

        const response = await axios.post("/api/commandes", data);
        if (response.status == 201) {
          this.commandeID = response.data.data.id;
          this.showModalRepas = !this.showModalRepas;
          this.isLoading = false;
          this.lignecommande();
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async lignecommande() {
      try {
        this.filters = "";
        this.addform.montant =
          this.filteredRepas[0].prix * this.addform.quantite;
        const datas = {
          quantite: this.addform.quantite,
          repas_id: this.addform.repas_id,
          commande_id: this.commandeID,
          montant: this.addform.montant,
        };

        const response = await axios.post("/api/lignecommandes", datas);
        if (response.status == 201) {
          this.createTransaction();
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async payementmethod() {
      try {
        const data = {
          transationId: this.transationID,
          commande_id: this.commandeID,
        };

        const response = await axios.post("/api/payments", data);
        if (response.status == 201) {
          this.addform = {};
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Votre commande a été enregistrée avec succès.",
            timeout: 5000,
          }).show();
        } else {
          this.showModalRepas = !this.showModalRepas;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async commentaire() {
      try {
        this.isLoading = true;
        const response = await axios.post("/api/commentaires", this.sendform);
        if (response.status == 201) {
          this.showModalCommentaires = !this.showModalCommentaires;
          this.sendform = {};
          this.isLoading = false;
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci pour votre commentaire",
            timeout: 5000,
          }).show();
        } else {
          this.showModalCommentaires = !this.showModalCommentaires;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async getRepas() {
      try {
        const response = await axios.get("/api/repas");
        if (response.data) {
          this.repass = response.data.data;
        }
      } catch (error) {
        console.log(error.data);
      }
    },

    async restaurant() {
      try {
        const response = await axios.get("/api/restaurants");
        if (response.data) {
          this.restaurants = response.data.data;
          this.filteredRestaurants = this.restaurants.filter(
            (restaurant) => restaurant.user.id === this.user
          );
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async createTransaction() {
      this.addform.montant = this.filteredRepas[0].prix * this.addform.quantite;
      openKkiapayWidget({
        amount: this.addform.montant,
        firstname: this.addform.name,
        lastname: this.addform.name,
        api_key: "361197d0e64411ec848227abfc492dc7",
        sandbox: true,
        phone: "61000000",
      });
    },
    successHandler(response) {
      this.transationID = response.transactionId;

      this.payementmethod();
    },
    nextSlide() {
      if (this.currentIndex + 3 < this.slides.length) {
        this.currentIndex += 1;
        this.updateSlider();
      }
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1;
        this.updateSlider();
      }
    },
    updateSlider() {
      const translateValue = -this.currentIndex * (100 / 3) + "%";
      this.$refs.slider.style.transform = "translateX(" + translateValue + ")";
    },
    goToPage(page) {
      // if (page >= 1 && page <= this.pages) {
      //   this.currentPage = page;
      // }
      this.currentPage = page;
    },
    loadMore() {
      // Ajoutez ici la logique pour charger plus d'éléments depuis votre source de données (définie localement)
      // Assurez-vous d'ajuster cette logique en fonction de votre situation réelle
      try {
        const newCards = this.getMoreCards(); // Remplacez par votre logique pour récupérer plus de cartes localement
        this.cards = this.cards.concat(newCards);
        this.currentPage++;
      } catch (error) {
        console.error(
          "Erreur lors du chargement des données supplémentaires",
          error
        );
      }
    },
    statusClass(status) {
      return {
        'bg-green-500': status === 'disponible',
        'bg-red-500': status === 'occuper'
      };
    },
  },
};
</script>

<style scoped>
.custom-background {
  background-image: url("~@/assets/hero.png");
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}
.bg-green-500 {
  background-color: #38a169; /* Vert */
}

.bg-red-500 {
  background-color: #e53e3e; /* Rouge */
}

/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@keyframes slide {
  0%,
  100% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-200%);
  }

  75% {
    transform: translateX(-300%);
  }
}
</style>
