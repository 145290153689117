<template>
  <div
    class="relative overflow-x-auto shadow-md sm:rounded-lg bg-white mt-9 p-4"
  >
    <div class="flex justify-between pb-4">
      <div v-show="showAlert">
        <AlertComponent :content="alert.message" type-alert="error" />
      </div>
      <label for="table-search" class="sr-only">Rechercher</label>
      <div class="relative hidden md:block">
        <div
          class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
        >
          <svg
            class="w-5 h-5 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          id="table-search"
          wire:model="search"
          class="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Rechercher ..."
        />
      </div>
      <div>
        <button
          class="text-white hover:bg-gray-500 rounded-lg font-medium bg-green-700 mr-4"
          @click="this.showModalCommande = true"
        >
          <span class="flex items-center p-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
              />
            </svg>
            Payement
          </span>
        </button>
      </div>
    </div>
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead
        class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
      >
        <tr>
          <th scope="col" class="px-6 py-3">Repas Nom</th>
          <th scope="col" class="px-6 py-3">Quantité</th>
          <th scope="col" class="px-6 py-3">Montant</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(commande, index) in Lignecommandes"
          :key="index"
          class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          <th
            scope="row"
            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          >
            {{ commande.repas.name }}
          </th>
          <th
            scope="row"
            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          >
            {{ commande.quantite.split(".")[0] }}
          </th>
          <td class="px-6 py-4">{{ commande.montant.split(".")[0] }}FCFA</td>
        </tr>
      </tbody>
    </table>
  </div>
  <DeleteModalFooter
    width="w-full md:w-2/3 lg:w-1/3"
    :is-open="showDeleteCommandeModal"
    @cancel="showDeleteCommandeModal = !showDeleteCommandeModal"
    @delete="deleteRestaurant()"
  >
    <template #header>Supprimer</template>
    <template #body> Vous voulez suppprimer cet commande </template>
  </DeleteModalFooter>
  <TheModal
    width="w-full md:w-2/3 lg:w-1/2"
    :is-open="showModalLivraisonUpdate"
    @close-modal="showModalLivraisonUpdate = false"
  >
    <template #header> Mettre à jour une Livraison</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="LivreCommande()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div class="col-span-8 sm:col-span-8">
                  <div class="mt-2">
                    <input
                      type="radio"
                      name="livraisonDetail"
                      id="livraisonDetailEnCours"
                      value="suspended"
                      v-model="sendform.status"
                    />
                    <label
                      for="livraisonDetailEnCours"
                      class="ml-1 text-xl font-blod"
                      >Suspendre</label
                    >

                    <input
                      type="radio"
                      class="ml-24"
                      name="livraisonDetail"
                      id="livraisonDetailTerminer"
                      value="Terminer"
                      v-model="sendform.status"
                      checked
                    />
                    <label
                      for="livraisonDetailTerminer"
                      class="ml-1 text-xl font-blod"
                      >Terminé</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <AddModalFooter
        @cancel="showModalLivraisonUpdate = false"
        @send="LivreCommande()"
      />
    </template>
  </TheModal>
  <TheModal
    width="w-full md:w-2/3 lg:w-1/4"
    :is-open="showModalCommande"
    @close-modal="showModalCommande = false"
  >
    <template #header> Numero de payement</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="LivreCommande()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div
                  v-if="numResto.celtispay !== null"
                  class="col-span-8 sm:col-span-8 flex items-center"
                >
                  <BaseLabel value="Numéro CELTISE" />
                  <label class="block font-medium text-sm text-gray-700 mt-2 bg-gray-200 px-4 rounded-full">{{
                    numResto.celtispay
                  }}</label>
                 <svg
                    @click="copyToClipboard(numResto.celtispay)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-green-400 ml-4 mt-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
                    />
                  </svg>
                </div>
                <div
                  v-if="numResto.moovpay !== null"
                  class="col-span-8 sm:col-span-8 flex items-center"
                >
                  <BaseLabel value="Numéro MOOV" />
                  <label class="block font-medium text-sm text-gray-700 mt-2 bg-gray-200 px-4 rounded-full">{{
                    numResto.moovpay
                  }}</label>
                  <svg
                    @click="copyToClipboard(numResto.moovpay)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-green-400 ml-4 mt-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
                    />
                  </svg>
                </div>
                <div
                  v-if="numResto.mtnpay !== null"
                  class="col-span-8 sm:col-span-8 flex items-center"
                >
                  <BaseLabel value="Numéro MTN" />
                  <label class="block font-medium text-sm text-gray-700 mt-2 bg-gray-200 px-4 rounded-full">{{
                    numResto.mtnpay
                  }}</label>

                  <svg
                    @click="copyToClipboard(numResto.mtnpay)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-green-400 ml-4 mt-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
  </TheModal>
</template>
<script>
import axios from "axios";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import TheModal from "../components/TheModal.vue";
import AddModalFooter from "../components/AddModalFooter.vue";
import DeleteModalFooter from "../components/DeleteModalFooter.vue";
import AlertComponent from "../components/AlertComponent.vue";
export default {
  name: "CommandeDash",
  components: {
    AlertComponent,
    DeleteModalFooter,
    TheModal,
    AddModalFooter,
  },
  data() {
    return {
      sendform: {
        valide: "Terminer",
      },
      sendforms: {
        status: "",
      },
      showAlert: false,
      alert: {
        message: "",
      },
      showModalLivraisonUpdate: false,
      Lignecommandes: [],
      filteredRestaurants: [],
      showModalCommande: false,
      user: "",
      restaurant_id: "",
      filter: "",
      livraisonID: "",
      livraisons: "",
      numResto: "",
    };
  },
  mounted() {
    this.filter = this.$route.params.id;
  },
  created() {
    this.profile();
    this.getLivraison();
    this.getLigneommande();
  },
  computed: {
    filteredLigneCommande() {
      const searchTerm = this.filter.toLowerCase();
      const filtered_data = this.Lignecommandes.filter((Lignecommandes) => {
        const name = Lignecommandes.commande.id.toLowerCase();
        return name.includes(searchTerm);
      });

      return filtered_data;
    },
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("Numéro copié dans le presse-papiers!");
        })
        .catch((err) => {
          console.error("Erreur lors de la copie: ", err);
        });
    },

    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.user = response.data.id;
        }
      } catch (error) {
        console.log(error.data);
      }
    },

    async LivreCommande() {
      try {
        this.isLoading = true;
        this.showModalLivraisonUpdate = false;
        const response = await axios.put(
          `/api/livraisons/${this.livraisonID}`,
          this.sendform
        );
        if (response.status == 200) {
          this.isLoading = false;
          this.livreurIDs = response.data.data.livreur.id;
          console.log(this.livreurIDs);
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci pour la validation de la livraison.",
            timeout: 5000,
          }).show();
          //this.LivreurDispo(this.livreurIDs);
          //if (this.sendform.status ==="Terminer") {
          //this.sendforms.status ="livrer",
          //this.ValideCommande(this.$route.params.id);
          //} else {
          // this.sendforms.status ="non livrer",
          //this.ValideCommande(this.$route.params.id);

          //}
          //this.getLivraison();
        } else {
          this.showAlert = true;
          this.isLoading = false;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.isLoading = false;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async ValideCommande(id) {
      try {
        const response = await axios.post(
          `/api/commandes/${id}`,
          this.sendforms
        );
        if (response.status == 200) {
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci le restaurant est informé de la livraison",
            timeout: 5000,
          }).show();
          this.profile();
        } else {
          this.showAlert = true;
          this.isLoading = false;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.isLoading = false;
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async getLivraison() {
      try {
        const response = await axios.get(
          `/api/livraisoncommande?commande_id=${this.$route.params.id}`
        );
        if (response.data) {
          this.livraisons = response.data.data;
          (this.livraisonID = this.livraisons[0].id),
            console.log(this.livraisonID);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async getLigneommande() {
      try {
        const response = await axios.get(
          `/api/lignecommandeid?commande_id=${this.$route.params.id}`
        );
        if (response.data) {
          this.Lignecommandes = response.data.data;
          this.numResto = this.Lignecommandes[0].commande.restaurant;
        }
      } catch (error) {
        console.log(error.data);
      }
    },
  },
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.ml-2 {
  margin-left: 0.5rem;
}
</style>
